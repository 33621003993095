import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { cashbackListRequest, cashbackUpdateStatusRequest, cashbackUpdateStatusRequestStatus } from '../../../redux/cashback/cashback.action'
import Select from 'react-select'
import { displayCurrencySymbolLeft, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserCustomer, isLoggedInUserOperator, isNull, removeSpecialCharAndChangeToPascal } from "../../../utils/Utils";
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import CommonButton from "../../../components/button/common-button/commonButton";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import SubTextLabel from "../../../components/label/sub-text-label/subTextLabel";
import { userBusinessListRequest } from "../../../redux/business/business.action";
import { businessLocationListRequest } from "../../../redux/business-location/businessLocation.action";
import { offerListRequest } from "../../../redux/offer/offer.action";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { searchOrAddCustomerRequest } from "../../../redux/customer/customer.action";
import { addCustomerCashbackRequest, addCustomerCashbackRequestStatus } from "../../../redux/customer-cashback/customerCashback.action";

const AddCustomerCashback = props => {
    const [isLoading, setLoading] = useState(false)
    const { user } = useSelector(state => state.user)
    const { addCustomerCashbackSuccess, addCustomerCashbackError, addCustomerCashbackReqStatus } = useSelector(state => state.customerCashback)
    const { offerListSuccess, offerListError, commonError } = useSelector((state) => state.offer)
    const { userBusinessListSuccess, userBusinessListError } = useSelector(state => state.business)
    const { businessLocationListSuccess, businessLocationListError } = useSelector(state => state.businessLocation)
    const { searchOrAddCustomerSuccess, searchOrAddCustomerError } = useSelector(state => state.customer)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [selectedBusiness, setSelectedBusiness] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    const [offerDropdown, setOfferDropdown] = useState()
    const [cashbackAmount, setCashbackAmount] = useState('')
    const [mobile, setMobile] = useState()

    useEffect(() => {
        setLoading(true)
        const data = {
            userId: user.data.userDetails.userId
        }
        dispatch(userBusinessListRequest(data))
    }, [])

    useEffect(() => {
        if (addCustomerCashbackReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addCustomerCashbackSuccess.message)
        } else if (addCustomerCashbackReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addCustomerCashbackError.message)
        }
        if (addCustomerCashbackReqStatus?.length) {
            dispatch(addCustomerCashbackRequestStatus(''))
        }
    }, [addCustomerCashbackSuccess, addCustomerCashbackError, addCustomerCashbackReqStatus])

    useEffect(() => {
        if (!isEmpty(userBusinessListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(userBusinessListError)) {
            setLoading(false)
        }
    }, [userBusinessListSuccess, userBusinessListError])

    useEffect(() => {
        if (!isEmpty(businessLocationListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(businessLocationListError)) {
            setLoading(false)
        }
    }, [businessLocationListSuccess, businessLocationListError])

    useEffect(() => {
        if (!isEmpty(offerListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(offerListError)) {
            setLoading(false)
        }
    }, [offerListSuccess, offerListError])

    useEffect(() => {
        if (!isEmpty(searchOrAddCustomerSuccess)) {
            setLoading(false)
        } else if (!isEmpty(searchOrAddCustomerError)) {
            setLoading(false)
            displayErrorToast(searchOrAddCustomerError.message)
        }
    }, [searchOrAddCustomerSuccess, searchOrAddCustomerError])

    useEffect(() => {
        if (!isNull(selectedBusiness)) {
            setLoading(true)
            const data = {
                businessId: selectedBusiness.businessId
            }
            dispatch(businessLocationListRequest(data))
        }
    }, [selectedBusiness])

    useEffect(() => {
        if (!isNull(selectedBusiness) && !isNull(selectedLocation)) {
            fetchOfferList()
        }
    }, [selectedLocation])

    useEffect(() => {
        if (!isNull(offerListSuccess)) {
            setLoading(false)
        } else if (!isNull(offerListError)) {
            setLoading(false)
        }
    }, [offerListSuccess, offerListError])

    const fetchOfferList = () => {
        if (!isEmpty(selectedBusiness) && !isEmpty(selectedLocation)) {
            const data = {
                businessId: selectedBusiness.businessId,
                locationId: selectedLocation.locationId,
            }
            setLoading(true)
            dispatch(offerListRequest(data))
        }
    }

    const isOfferExpired = (offerEndDate, currentDate) => {
        // const momen1 = new Date(offerEndDate)
        // const moment2 = new Date(currentDate)
        // if (momen1.getTime() > moment2.getTime()) {
        //     return true
        // } else {
        //     return false
        // }
        if (moment(currentDate).isAfter(moment(offerEndDate))) {
            return true
        } else {
            return false
        }
    }

    const handleSearchCustomerClick = () => {
        if (isNull(mobile)) {
            displayErrorToast('Mobile number cannot be blank')
        } else {
            const mobileWithPlusSymbol = mobile.replace('+', "")
            const data = {
                mobile: parseInt(mobileWithPlusSymbol)
            }
            setLoading(true)
            dispatch(searchOrAddCustomerRequest(data))
        }
    }

    const handleSubmitClick = () => {
        if (isEmpty(selectedBusiness)) {
            displayErrorToast('Business not selected')
        } else if (isEmpty(selectedLocation)) {
            displayErrorToast('Business location not selected')
        } else if (isEmpty(offerDropdown)) {
            displayErrorToast('Offer not selected')
        } else if (isEmpty(searchOrAddCustomerSuccess)) {
            displayErrorToast('Customer not selected')
        } else if (isEmpty(cashbackAmount)) {
            displayErrorToast('Cashback amount cannot be blank')
        } else if (parseFloat(cashbackAmount) <= 0) {
            displayErrorToast('Invalid cashback amount')
        } else {
            const data = {
                businessId: selectedBusiness.businessId,
                locationId: selectedLocation.locationId,
                cashbackAmount: parseFloat(cashbackAmount),
                offerId: offerDropdown.offerId,
                customerId: searchOrAddCustomerSuccess.data.customerId
            }
            setLoading(true)
            dispatch(addCustomerCashbackRequest(data))

        }
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Add Customer Cashback' />
                <CommonFormBorder>
                    <SubTextLabel label='Enter details to add customer cashback' />
                    <FormFieldLabel className='up-bold-label' label='Business Information' />
                    <FormFieldLabel label='Select Business' />
                    {userBusinessListSuccess && userBusinessListSuccess.data && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedBusiness}
                        getOptionLabel={e => e.businessName}
                        getOptionValue={e => e}
                        options={userBusinessListSuccess.data}
                        placeholder='Select Business'
                        onChange={e => setSelectedBusiness(e)} />}
                    {!isEmpty(userBusinessListError) && <div className="content-error">{userBusinessListError.message}</div>}
                    {!isEmpty(selectedBusiness) && <FormFieldLabel label='Select Business Location' />}
                    {!isEmpty(selectedBusiness) && businessLocationListSuccess && businessLocationListSuccess.data && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedLocation}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={businessLocationListSuccess.data}
                        placeholder='Select Location'
                        onChange={e => setSelectedLocation(e)} />}
                    {!isEmpty(selectedBusiness) && !isEmpty(businessLocationListError) && <div className="content-error">{businessLocationListError.message}</div>}
                    {!isEmpty(selectedBusiness) && !isEmpty(selectedLocation) && <FormFieldLabel className='up-bold-label' label='Offer Information' />}
                    {!isEmpty(selectedBusiness) && !isEmpty(selectedLocation) && offerListSuccess && offerListSuccess.data && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={offerDropdown}
                        getOptionLabel={e => `${e.offerName} ${isOfferExpired(e.endDate, e.currentTime) ? ' - Expired' : ''}`}
                        getOptionValue={e => e}
                        options={offerListSuccess.data}
                        placeholder='Select Offer'
                        onChange={e => setOfferDropdown(e)} />}
                    {!isEmpty(selectedBusiness) && !isEmpty(selectedLocation) && !isEmpty(offerListError) && <div className="content-error">{offerListError.message}</div>}
                    {!isEmpty(selectedBusiness) && !isEmpty(selectedLocation) && !isEmpty(offerDropdown) && <FormFieldLabel label='Search Customer' />}
                    {!isEmpty(selectedBusiness) && !isEmpty(selectedLocation) && !isEmpty(offerDropdown) && <div className="acc-mobile-row">
                        <div className="acc-mobile-container">
                            <PhoneInput
                                defaultCountry="US"
                                placeholder="Mobile Number"
                                value={mobile}
                                onChange={setMobile} />
                        </div>
                        <div className="acc-mobile-search-img-container" onClick={() => { handleSearchCustomerClick() }}>
                            <img src={require('../../../assets/ic_search_icon.png')} className="img" />
                        </div>
                    </div>}
                    {!isEmpty(selectedBusiness) && !isEmpty(selectedLocation) && !isEmpty(offerDropdown) && !isEmpty(searchOrAddCustomerSuccess) && <CommonInputFloatFull
                        type='number'
                        id='cashbackAmount'
                        onChange={e => setCashbackAmount(e.target.value)}
                        placeholder='Cashback Amount'
                        value={cashbackAmount}
                    />}
                    <ButtonRow>
                        <CommonButton label='Submit' onClick={() => { handleSubmitClick() }} />
                    </ButtonRow>
                </CommonFormBorder>

            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AddCustomerCashback