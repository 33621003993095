import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'
import moment from "moment";
// import { offerListRequest } from '../../../redux/offer/offer.action'
// import { receiptListRequest } from '../../../redux/receipt/receipt.action'
import { addInvoiceRequest, addInvoiceRequestStatus } from '../../../redux/invoice/invoice.action'
import { useDispatch, useSelector } from 'react-redux'
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import { displayCurrencySymbolLeft, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserOperator, isNull } from "../../../utils/Utils";
import { useNavigate } from 'react-router-dom';
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import SubTextLabel from "../../../components/label/sub-text-label/subTextLabel";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import { Checkbox } from "@mui/material";
import { cashbackListRequest } from '../../../redux/cashback/cashback.action'

const CreateInvoice = props => {
    const [isLoading, setLoading] = useState(false)
    const { user } = useSelector(state => state.user)
    const { addInvoiceSuccess, addInvoiceError, addInvoiceReqStatus } = useSelector((state) => state.invoice)
    const { cashbackListSuccess, cashbackListError } = useSelector(state => state.cashback)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [selectedCashbackRequestList, setSelectedCashbackRequestList] = useState([])
    const [displayCbRequestList, setDisplayCbRequestList] = useState(false)
    const [countryDropdown, setCountryDropdown] = useState();

    useEffect(() => {
        if (addInvoiceReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addInvoiceSuccess.message)
            setTimeout(() => {
                navigate(-1)
            }, 800)
        } else if (addInvoiceReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addInvoiceError.message)
        }
        if (addInvoiceReqStatus.length) {
            dispatch(addInvoiceRequestStatus(''))
        }
    }, [addInvoiceSuccess, addInvoiceError, addInvoiceReqStatus])

    const fetchCashbackRequestList = (e) => {
        setDisplayCbRequestList(false)
        setLoading(true)
        let data = {
            country: e.name
        }
        dispatch(cashbackListRequest(data))
    }

    useEffect(() => {
        if (!isNull(cashbackListSuccess)) {
            setLoading(false)
            setDisplayCbRequestList(true)
        } else if (!isNull(cashbackListError)) {
            setLoading(false)
            setDisplayCbRequestList(true)
        }
    }, [cashbackListSuccess, cashbackListError])

    const isSameBusinessAndLocation = () => {
        if (isEmpty(selectedCashbackRequestList)) {
            return false
        }

        const { businessId, locationId } = selectedCashbackRequestList[0]
        console.log('businessId', businessId);
        console.log('locationId', locationId);
        console.log('selectedCashbackRequestList', selectedCashbackRequestList);

        return selectedCashbackRequestList.every(item => parseFloat(item.businessId) === parseFloat(businessId) && parseFloat(item.locationId) === parseFloat(locationId))

    }

    const handleCreateInvoiceClick = () => {
        if (!isLoggedInUserOperator(user)) {
            displayErrorToast('Only operator is allowed to create invoice')
        } else if (isEmpty(selectedCashbackRequestList)) {
            displayErrorToast('Select atleast 1 cashback request')
        } else if (!isSameBusinessAndLocation()) {
            displayErrorToast('Some cashback have different business and location. Kindly select cashback request from same business and location')
        } else {
            let cashbackReqIds = []
            selectedCashbackRequestList.forEach(e => {
                cashbackReqIds.push(e.cashbackRequestId)
            })
            const { businessId, locationId } = selectedCashbackRequestList[0]
            const data = {
                businessId: businessId,
                locationId: locationId,
                operatorId: user.data.userDetails.userId,
                productCode: APP_CONSTANTS.PRODUCT_NAME,
                requestIds: cashbackReqIds
            }
            setLoading(true)
            dispatch(addInvoiceRequest(data))
        }
    }

    const isCashbackRequestSelected = item => {
        return selectedCashbackRequestList.some(request => request.cashbackRequestId === item.cashbackRequestId);
    }

    const addOrRemoveFromSelectedCbRequest = (e) => {
        setSelectedCashbackRequestList((currentList) => {
            const receiptIndex = currentList.findIndex(item => item.cashbackRequestId === e.cashbackRequestId);
            if (receiptIndex === -1) {
                return [...currentList, e]; // Add the object if it doesn't exist
            } else {
                const updatedList = [...currentList];
                updatedList.splice(receiptIndex, 1); // Remove the object if it exists
                return updatedList;
            }
        });
    }

    const getCustomerName = item => {
        if (isEmpty(item.customerDetail.firstName) && isEmpty(item.customerDetail.lastName)) {
            return `+${item.customerDetail.mobile}`
        } else {
            return `${item.customerDetail.firstName} ${item.customerDetail.lastName}`
        }
    }

    const getAmountWithCurrency = (e, amount) => {
        return (displayCurrencySymbolLeft(e.currencyRegion) ?
            <span>
                {e.currencySymbol} {amount}
            </span>
            : <span>
                {amount} {e.currencySymbol}
            </span>
        )
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Create New Invoice' />
                <CommonFormBorder>
                    <SubTextLabel label='Enter below details to create new invoice' />
                    {isLoggedInUserOperator(user) && <div>
                        <FormFieldLabel className='up-bold-label' label='Select Location' />
                        {isEmpty(user.data.userDetails.accessLocations) && <div>No location access provided. Kindly request access to view receipts</div>}
                        {!isEmpty(user.data.userDetails.accessLocations) &&
                            <Select
                                menuPlacement="auto"
                                className='select-full-width-no-border'
                                value={countryDropdown}
                                getOptionLabel={e => e.country}
                                getOptionValue={e => e}
                                options={user.data.userDetails.accessLocations}
                                placeholder='Select Country'
                                onChange={e => {
                                    setCountryDropdown(e)
                                    fetchCashbackRequestList(e)
                                }} />
                        }
                    </div>}
                    {displayCbRequestList && !isNull(cashbackListSuccess) && cashbackListSuccess.data.map(e => (
                        <div className="ci-receipt-list-item-parent-container">
                            <div className="ci-receipt-list-item-container">
                                <div>
                                    {/* <div className="ci-receipt-number-label">Request Id : {e.cashbackRequestId}</div> */}
                                    <div className="ci-receipt-customer-label">{getCustomerName(e)}</div>
                                    <div className="ci-receipt-amount-label">{getAmountWithCurrency(e, e.requestAmount)}</div>
                                    <div className="ci-receipt-business-label">{e.businessName}</div>
                                    <div className="ci-receipt-location-label">{e.city}, {e.state}, {e.country}</div>
                                </div>
                                <Checkbox
                                    checked={isCashbackRequestSelected(e)}
                                    onChange={e1 => addOrRemoveFromSelectedCbRequest(e)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium" />

                            </div>
                            {e.invoiceNumber > 0 && <div className="ci-invoice-warning">Invoice already created for cashback request with Invoice number: {e.invoiceNumber}</div>}
                        </div>
                    ))}
                    {displayCbRequestList && !isNull(cashbackListError) && <div>{cashbackListError.message}</div>}
                    <ButtonRow>
                        <CommonButton label='Create Invoice' onClick={() => {
                            handleCreateInvoiceClick()
                        }} />
                    </ButtonRow>
                </CommonFormBorder>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default CreateInvoice