export const BusinessLocationActionTypes = {
    LOGOUT: 'LOGOUT',
    COMMON_ERROR: 'COMMON_ERROR',

    ADD_BUSINESS_LOCATION_REQUEST: 'ADD_BUSINESS_LOCATION_REQUEST',
    ADD_BUSINESS_LOCATION_REQUEST_SUCCESS: 'ADD_BUSINESS_LOCATION_REQUEST_SUCCESS',
    ADD_BUSINESS_LOCATION_REQUEST_ERROR: 'ADD_BUSINESS_LOCATION_REQUEST_ERROR',
    ADD_BUSINESS_LOCATION_REQUEST_STATUS: 'ADD_BUSINESS_LOCATION_REQUEST_STATUS',

    BUSINESS_LOCATION_LIST_REQUEST: 'BUSINESS_LOCATION_LIST_REQUEST',
    BUSINESS_LOCATION_LIST_REQUEST_SUCCESS: 'BUSINESS_LOCATION_LIST_REQUEST_SUCCESS',
    BUSINESS_LOCATION_LIST_REQUEST_ERROR: 'BUSINESS_LOCATION_LIST_REQUEST_ERROR',

    BUSINESS_LOCATION_DETAIL_REQUEST: 'BUSINESS_LOCATION_DETAIL_REQUEST',
    BUSINESS_LOCATION_DETAIL_REQUEST_SUCCESS: 'BUSINESS_LOCATION_DETAIL_REQUEST_SUCCESS',
    BUSINESS_LOCATION_DETAIL_REQUEST_ERROR: 'BUSINESS_LOCATION_DETAIL_REQUEST_ERROR',

    UPDATE_BUSINESS_LOCATION_DETAIL_REQUEST: 'UPDATE_BUSINESS_LOCATION_DETAIL_REQUEST',
    UPDATE_BUSINESS_LOCATION_DETAIL_REQUEST_SUCCESS: 'UPDATE_BUSINESS_LOCATION_DETAIL_REQUEST_SUCCESS',
    UPDATE_BUSINESS_LOCATION_DETAIL_REQUEST_ERROR: 'UPDATE_BUSINESS_LOCATION_DETAIL_REQUEST_ERROR',
    UPDATE_BUSINESS_LOCATION_DETAIL_REQUEST_STATUS: 'UPDATE_BUSINESS_LOCATION_DETAIL_REQUEST_STATUS'
}
