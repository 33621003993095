import React, { useState, useEffect } from "react";
import './styles.css'
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import { isNull } from "../../../utils/Utils";
import { operatorListRequest } from '../../../redux/user/user.action'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import TopButton from "../../../components/button/top-button/topButton";
import { ROUTES } from "../../../utils/AppConstants";

const OperatorList = props => {
    const [isLoading, setLoading] = useState(false)
    const { user, operatorListSuccess, operatorListError } = useSelector(state => state.user)
    const dispatch = useDispatch()
    let navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        dispatch(operatorListRequest())
    }, [])

    useEffect(() => {
        if (!isNull(operatorListSuccess)) {
            setLoading(false)
        } else if (!isNull(operatorListError)) {
            setLoading(false)
        }
    }, [operatorListSuccess, operatorListError])

    const handleAddOperatorClick = () => {
        navigate(ROUTES.operatorRegister)
    }

    const handleCardClick = element => {
        navigate(ROUTES.operatorDetail, { state: element.userId })
    }

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow className='margin-top-10'>
                    <ScreenLabel label='Operators' />
                    <TopButton label='Add Operator'
                        onClick={() => {
                            handleAddOperatorClick()
                        }} />
                </CommonInputRow>
                <div className="table-scroll-container">
                    {operatorListSuccess && operatorListSuccess.data && <table class="table table-hover margin-top-10">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">Name</th>
                                <th scope="col" className="rl-table-col-title text-center">Mobile</th>
                                <th scope="col" className="rl-table-col-title text-center">Email</th>
                                <th scope="col" className="rl-table-col-title text-center">Status</th>
                                <th scope="col" className="rl-table-col-title text-center">Date</th>
                                <th scope="col" className="rl-table-col-title text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {operatorListSuccess.data.map(element => (
                                <tr className="rl-table-row-container">
                                    <td className="rl-table-col-item">{element.firstName} {element.lastName}</td>
                                    <td className="rl-table-col-item text-center">{element.mobile}</td>
                                    <td className="rl-table-col-item text-center">{element.email}</td>
                                    <td className={`rl-table-col-item text-center ${element.status === 1 ? 'active' : 'in-active'}`}>{element.status === 1 ? 'Active' : 'In-Active'}</td>
                                    <td className="rl-table-col-item text-center">{moment(element.createdOn).utc().format('DD MMM YYYY')}</td>
                                    <td className="rl-table-col-item text-center" >
                                        <div className="resl-btn-container" onClick={() => { handleCardClick(element) }}>View Details</div></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>

                {!isNull(operatorListError) && <div className="content-error">{operatorListError.message}</div>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default OperatorList