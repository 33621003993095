import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addInvoiceAPI, invoiceDetailAPI, invoiceListAPI, invoiceUpdateStatusAPI
} from '../api-requests/invoice';
import {
    addInvoiceRequestError, addInvoiceRequestSuccess, invoiceDetailRequestError,
    invoiceDetailRequestSuccess, invoiceListRequestError, invoiceListRequestSuccess,
    invoiceUpdatePaymentRequestError, invoiceUpdatePaymentRequestSuccess
} from './invoice.action';
import { InvoiceActionTypes } from './invoice.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleInvoiceUpdatePayment({ payload }) {
    try {
        const response = yield call(invoiceUpdateStatusAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(invoiceUpdatePaymentRequestSuccess(decryptedData))
            } else {
                yield put(invoiceUpdatePaymentRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: InvoiceActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(invoiceUpdatePaymentRequestError(error.response.data))
        }
    }
}

export function* invoiceUpdatePaymentReq() {
    yield takeLatest(InvoiceActionTypes.INVOICE_UPDATE_PAYMENT_REQUEST, handleInvoiceUpdatePayment)
}

function* handleInvoiceList({ payload }) {
    try {
        const response = yield call(invoiceListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(invoiceListRequestSuccess(decryptedData))
            } else {
                yield put(invoiceListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: InvoiceActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(invoiceListRequestError(error.response.data))
        }
    }
}

export function* invoiceListReq() {
    yield takeLatest(InvoiceActionTypes.INVOICE_LIST_REQUEST, handleInvoiceList)
}

function* handleInvoiceDetail({ payload }) {
    try {
        const response = yield call(invoiceDetailAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(invoiceDetailRequestSuccess(decryptedData))
            } else {
                yield put(invoiceDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: InvoiceActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(invoiceDetailRequestError(error.response.data))
        }
    }
}

export function* invoiceDetailReq() {
    yield takeLatest(InvoiceActionTypes.INVOICE_DETAIL_REQUEST, handleInvoiceDetail)
}

function* handleAddInvoice({ payload }) {
    try {
        const response = yield call(addInvoiceAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addInvoiceRequestSuccess(decryptedData))
            } else {
                yield put(addInvoiceRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: InvoiceActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(addInvoiceRequestError(error.response.data))
        }
    }
}

export function* addInvoiceReq() {
    yield takeLatest(InvoiceActionTypes.ADD_INVOICE_REQUEST, handleAddInvoice)
}