export const ROUTES = {
    home: `/`,
    business: '/business',
    customerLogin: '/customerLogin',
    vendorLogin: '/vendorLogin',
    vendorCreateAccount: '/vendorCreateAccount',
    operatorRegister: '/operatorRegister',
    operatorList: '/operatorList',
    operatorDetail: '/operatorDetail',
    customerProfile: '/customerProfile',
    offerList: '/offerList',
    addOffer: '/addOffer',
    offerDetail: '/offerDetail',
    receiptList: '/receiptList',
    receiptDetail: '/receiptDetail',
    uploadReceipt: '/uploadReceipt',
    invoiceList: '/invoiceList',
    invoiceDetail: '/invoiceDetail',
    createInvoice: '/createInvoice',
    cashbackRequestList: '/cashbackRequestList',
    privacyPolicy: '/privacyPolicy',
    cookiePolicy: '/cookiePolicy',
    updateProfile: '/updateProfile',
    addCustomerCashback: 'addCustomerCashback',
    customerCashbackList: 'customerCashbackList',
    vendorCashbackList: 'vendorCashbackList'
}

export const API_CONSTANTS = {
    SUCCESS_STATUS: 'SUCCESS_STATUS',
    ERROR_STATUS: 'ERROR_STATUS'
}

export const ORGANISATION_DETAIL = {
    ORGANISATION_NAME: 'GoMoBites LLC',
    ORG_ADDRESS_LINE2: 'Dublin, CA 94568',
    ORG_SUPPORT_EMAIL: 'support@gomobites.com'
}

export const APP_CONSTANTS = {
    PRODUCT_NAME: 'JUSTGOMOLITE',
    USER: 'USER',
    USER_TOKEN: 'USER_TOKEN',
    LOGOUT: 'LOGOUT',
    RESET_STATE_CITY: 'RESET_STATE_CITY',
    DISPLAY_COOKIE_CONSENT: 'DISPLAY_COOKIE_CONSENT',
    DISPLAY_NEWS_LETTER_CONSENT: 'DISPLAY_NEWS_LETTER_CONSENT',
    MENU_ITEM_TYPE: {
        OPERATOR_LIST: 'OPERATOR_LIST',
        OFFER_LIST: 'OFFER_LIST',
        RECEIPT_LIST: 'RECEIPT_LIST',
        INVOICE_LIST: 'INVOICE_LIST',
        CASHBACK_LIST: 'CASHBACK_LIST',
        PROFILE: 'PROFILE'
    },
    MENU_ITEM_DISPLAY_NAME: {
        OPERATOR_LIST: 'Operators',
        OFFER_LIST: 'Offers',
        INVOICE_LIST: 'Invoices',
        RECEIPT_LIST: 'Receipts',
        CASHBACK_LIST: 'Cashback Requests',
        CUSTOMER_CASHBACK_LIST: 'Cashback Rewards',
        PROFILE: 'Profile',
        LOGOUT: 'Log Out'
    },
    ENVIRONMENT: {
        LOCAL: 'LOCAL',
        LOCAL_CUSTOMER: 'LOCAL_CUSTOMER',
        DEV: 'DEV',
        PROD: 'PROD'
    },
    KEY_FCM_TOKEN: 'KEY_FCM_TOKEN',
    SESSION_EXPIRE_MESSAGE: 'Your session expire. Please try login again',
    COMMON_ERROR: 'COMMON_ERROR',
    EMAIL_ALREADY_SUBSCRIBED_ERROR_MESSAGE: 'Email already subscribed',
    DEVICE_TYPE: {
        ANDROID: 'ANDROID',
        IOS: 'IOS',
        DESKTOP: 'DESKTOP',
        WINDOWS: 'WINDOWS',
        MAC_OS: 'MAC_OS'
    },
    KEY_DEVICE_ID: 'deviceId',
    USER_ROLE_TYPE: {
        ADMIN: 'ADMIN',
        OPERATOR: 'OPERATOR',
        VENDOR: 'VENDOR',
        CUSTOMER: 'CUSTOMER'
    },
    RECEIPT_STATUS: {
        PENDING: 'PENDING',
        OPERATOR_APPROVED: 'OPERATOR_APPROVED',
        AWAITING_VENDOR_APPROVAL: 'AWAITING_VENDOR_APPROVAL',
        VENDOR_APPROVED: 'VENDOR_APPROVED',
        CANCELLED: 'CANCELLED',
        DISPUTED: 'DISPUTED',
        ERROR: 'ERROR',
        COMPLETED: 'COMPLETED'
    },
    PAYMENT_STATUS: {
        PENDING: 'PENDING',
        PAID: 'PAID',
        ERROR: 'ERROR'
    },
    CASHBACK_REQUEST_STATUS: {
        PENDING: 'PENDING',
        IN_PROCESS: 'IN_PROCESS',
        PAID: 'PAID',
        REJECTED: 'REJECTED',
        ERROR: 'ERROR'
    },
    DEFAULT_LOCATION_KEY: 'USA'

}