import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { customerCashbackListRequest } from '../../../redux/customer-cashback/customerCashback.action'
import Select from 'react-select'
import { displayCurrencySymbolLeft, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserCustomer, isLoggedInUserOperator, isLoggedInUserVendor, isNull, removeSpecialCharAndChangeToPascal } from "../../../utils/Utils";
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import CommonButton from "../../../components/button/common-button/commonButton";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import { userBusinessListRequest } from "../../../redux/business/business.action";
import { businessLocationListRequest } from "../../../redux/business-location/businessLocation.action";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";


const VendorCashbackList = props => {
    const [isLoading, setLoading] = useState(false)
    const { user } = useSelector(state => state.user)
    const { customerCashbackListSuccess, customerCashbackListError } = useSelector(state => state.customerCashback)
    const { userBusinessListSuccess, userBusinessListError } = useSelector(state => state.business)
    const { businessLocationListSuccess, businessLocationListError } = useSelector(state => state.businessLocation)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [selectedBusiness, setSelectedBusiness] = useState()
    const [selectedLocation, setSelectedLocation] = useState()

    useEffect(() => {
        setLoading(true)
        const data = {
            userId: user.data.userDetails.userId
        }
        dispatch(userBusinessListRequest(data))
    }, [])

    useEffect(() => {
        if (!isEmpty(customerCashbackListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(customerCashbackListError)) {
            setLoading(false)
        }
    }, [customerCashbackListSuccess, customerCashbackListError])

    useEffect(() => {
        if (!isEmpty(userBusinessListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(userBusinessListError)) {
            setLoading(false)
        }
    }, [userBusinessListSuccess, userBusinessListError])

    useEffect(() => {
        if (!isEmpty(businessLocationListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(businessLocationListError)) {
            setLoading(false)
        }
    }, [businessLocationListSuccess, businessLocationListError])

    useEffect(() => {
        if (!isNull(selectedBusiness)) {
            setLoading(true)
            const data = {
                businessId: selectedBusiness.businessId
            }
            dispatch(businessLocationListRequest(data))
        }
    }, [selectedBusiness])

    useEffect(() => {
        if (!isNull(selectedBusiness) && !isNull(selectedLocation)) {
            fetchCustomerCashbackList()
            // setLoading(true)
            // const data = {
            //     businessId: selectedBusiness.businessId
            // }
            // dispatch(businessLocationListRequest(data))
        }
    }, [selectedLocation])

    const fetchCustomerCashbackList = () => {
        if (!isEmpty(selectedBusiness) && !isEmpty(selectedLocation)) {
            setLoading(true);
            const data = {
                businessId: selectedBusiness.businessId,
                locationId: selectedLocation.locationId
            };
            dispatch(customerCashbackListRequest(data));
        }
    }

    const getAmountWithCurrency = (e, amount) => {
        return (displayCurrencySymbolLeft(e.currencyRegion) ?
            <span>
                {e.currencySymbol} {amount}
            </span>
            : <span>
                {amount} {e.currencySymbol}
            </span>
        )
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Cashback Rewards' />
                {isLoggedInUserVendor(user) === true &&
                    <CommonInputRow className='margin-top-10'>
                        <div className="vcl-list-select-container">
                            <div className="vcl-list-select-heading">Select Business</div>
                            {!isEmpty(userBusinessListSuccess) && !isEmpty(userBusinessListSuccess.data) && <Select
                                className='select-full-width-no-border'
                                value={selectedBusiness}
                                getOptionLabel={e => e.businessName}
                                getOptionValue={e => e}
                                options={userBusinessListSuccess.data}
                                placeholder='Select Business'
                                onChange={e => setSelectedBusiness(e)} />}
                            {!isEmpty(userBusinessListError) && <div className="content-error">{userBusinessListError.message}</div>}
                        </div>
                        {!isEmpty(selectedBusiness) && <div className="vcl-list-select-container">
                            <div className="vcl-list-select-heading">Select Location</div>
                            {!isEmpty(businessLocationListSuccess) && !isEmpty(businessLocationListSuccess.data) && <Select
                                className='select-full-width-no-border'
                                value={selectedLocation}
                                getOptionLabel={e => e.name}
                                getOptionValue={e => e}
                                options={businessLocationListSuccess.data}
                                placeholder='Select Location'
                                onChange={e => setSelectedLocation(e)} />}
                            {!isEmpty(businessLocationListError) && <div className="content-error">{businessLocationListError.message}</div>}
                        </div>}
                    </CommonInputRow>}
                {!isEmpty(customerCashbackListSuccess) && !isEmpty(customerCashbackListSuccess.data) && <div className="vcl-heading-container">
                    <div className="vcl-heading-label-left">Customer Name</div>
                    <div className="vcl-heading-label-right">Cashback Amount</div>
                </div>}
                {!isEmpty(customerCashbackListSuccess) && !isEmpty(customerCashbackListSuccess.data) && <div>
                    {customerCashbackListSuccess.data.map(e => (<div className="vcl-heading-container">
                        <div className="vcl-heading-value">+{e.customerMobile}</div>
                        <div className="vcl-heading-value">{getAmountWithCurrency(e, e.cashbackAmount)}</div>
                    </div>))}
                </div>}
                {!isEmpty(customerCashbackListError) && <div className="content-error">{customerCashbackListError.message}</div>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default VendorCashbackList