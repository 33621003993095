import { BusinessLocationActionTypes } from './businessLocation.types'

//Add Business Location
export const addBusinessLocationRequest = data => ({
    type: BusinessLocationActionTypes.ADD_BUSINESS_LOCATION_REQUEST,
    payload: data
})

export const addBusinessLocationRequestSuccess = data => ({
    type: BusinessLocationActionTypes.ADD_BUSINESS_LOCATION_REQUEST_SUCCESS,
    payload: data
})

export const addBusinessLocationRequestError = data => ({
    type: BusinessLocationActionTypes.ADD_BUSINESS_LOCATION_REQUEST_ERROR,
    payload: data
})

export const addBusinessLocationRequestStatus = data => ({
    type: BusinessLocationActionTypes.ADD_BUSINESS_LOCATION_REQUEST_STATUS,
    payload: data
})

//Business Location List
export const businessLocationListRequest = data => ({
    type: BusinessLocationActionTypes.BUSINESS_LOCATION_LIST_REQUEST,
    payload: data
})

export const businessLocationListRequestSuccess = data => ({
    type: BusinessLocationActionTypes.BUSINESS_LOCATION_LIST_REQUEST_SUCCESS,
    payload: data
})

export const businessLocationListRequestError = data => ({
    type: BusinessLocationActionTypes.BUSINESS_LOCATION_LIST_REQUEST_ERROR,
    payload: data
})

//Business Location Detail
export const businessLocationDetailRequest = data => ({
    type: BusinessLocationActionTypes.BUSINESS_LOCATION_DETAIL_REQUEST,
    payload: data
})

export const businessLocationDetailRequestSuccess = data => ({
    type: BusinessLocationActionTypes.BUSINESS_LOCATION_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const businessLocationDetailRequestError = data => ({
    type: BusinessLocationActionTypes.BUSINESS_LOCATION_DETAIL_REQUEST_ERROR,
    payload: data
})

//Update Business Location
export const updateBusinessLocationDetailRequest = data => ({
    type: BusinessLocationActionTypes.UPDATE_BUSINESS_LOCATION_DETAIL_REQUEST,
    payload: data
})

export const updateBusinessLocationDetailRequestSuccess = data => ({
    type: BusinessLocationActionTypes.UPDATE_BUSINESS_LOCATION_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const updateBusinessLocationDetailRequestError = data => ({
    type: BusinessLocationActionTypes.UPDATE_BUSINESS_LOCATION_DETAIL_REQUEST_ERROR,
    payload: data
})

export const updateBusinessLocationDetailRequestStatus = data => ({
    type: BusinessLocationActionTypes.UPDATE_BUSINESS_LOCATION_DETAIL_REQUEST_STATUS,
    payload: data
})