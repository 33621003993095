import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addBusinessAPI, businessDetailAPI, updateBusinessDetailsAPI, userBusinessListAPI
} from '../api-requests/business';
import {
    addBusinessRequestError, addBusinessRequestSuccess, businessDetailsRequestError,
    businessDetailsRequestSuccess, updateBusinessDetailsRequestError, updateBusinessDetailsRequestSuccess,
    userBusinessListRequestError, userBusinessListRequestSuccess
} from './business.action';
import { BusinessActionTypes } from './business.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleUserBusinessList({ payload }) {
    try {
        const response = yield call(userBusinessListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(userBusinessListRequestSuccess(decryptedData))
            } else {
                yield put(userBusinessListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: BusinessActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(userBusinessListRequestError(error.response.data))
        }
    }
}

export function* userBusinessListReq() {
    yield takeLatest(BusinessActionTypes.USER_BUSINESS_LIST_REQUEST, handleUserBusinessList)
}

function* handleUpdateBusinessDetail({ payload }) {
    try {
        const response = yield call(updateBusinessDetailsAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateBusinessDetailsRequestSuccess(decryptedData))
            } else {
                yield put(updateBusinessDetailsRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: BusinessActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(updateBusinessDetailsRequestError(error.response.data))
        }
    }
}

export function* updateBusinessDetailReq() {
    yield takeLatest(BusinessActionTypes.UPDATE_BUSINESS_REQUEST, handleUpdateBusinessDetail)
}

function* handleBusinessDetail({ payload }) {
    try {
        const response = yield call(businessDetailAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(businessDetailsRequestSuccess(decryptedData))
            } else {
                yield put(businessDetailsRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: BusinessActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(businessDetailsRequestError(error.response.data))
        }
    }
}

export function* businessDetailReq() {
    yield takeLatest(BusinessActionTypes.BUSINESS_DETAIL_REQUEST, handleBusinessDetail)
}

function* handleAddBusiness({ payload }) {
    try {
        const response = yield call(addBusinessAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addBusinessRequestSuccess(decryptedData))
            } else {
                yield put(addBusinessRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: BusinessActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(addBusinessRequestError(error.response.data))
        }
    }
}

export function* addBusinessReq() {
    yield takeLatest(BusinessActionTypes.ADD_BUSINESS_REQUEST, handleAddBusiness)
}