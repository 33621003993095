import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addLocationAPI, businessLocationDetailAPI, businessLocationListAPI, updateBusinessLocationDetailsAPI
} from '../api-requests/businessLocation';
import {
    addBusinessLocationRequestError, addBusinessLocationRequestSuccess, businessLocationDetailRequestError,
    businessLocationDetailRequestSuccess, businessLocationListRequestError, businessLocationListRequestSuccess,
    updateBusinessLocationDetailRequestError, updateBusinessLocationDetailRequestSuccess
} from './businessLocation.action';
import { BusinessLocationActionTypes } from './businessLocation.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleUpdateBusinessLocationDetail({ payload }) {
    try {
        const response = yield call(updateBusinessLocationDetailsAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateBusinessLocationDetailRequestSuccess(decryptedData))
            } else {
                yield put(updateBusinessLocationDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: BusinessLocationActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(updateBusinessLocationDetailRequestError(error.response.data))
        }
    }
}

export function* updateBusinessLocationReq() {
    yield takeLatest(BusinessLocationActionTypes.UPDATE_BUSINESS_LOCATION_DETAIL_REQUEST, handleUpdateBusinessLocationDetail)
}

function* handleBusinessLocationList({ payload }) {
    try {
        const response = yield call(businessLocationListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(businessLocationListRequestSuccess(decryptedData))
            } else {
                yield put(businessLocationListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: BusinessLocationActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(businessLocationListRequestError(error.response.data))
        }
    }
}

export function* businessLocationListReq() {
    yield takeLatest(BusinessLocationActionTypes.BUSINESS_LOCATION_LIST_REQUEST, handleBusinessLocationList)
}

function* handleBusinessLocationDetail({ payload }) {
    try {
        const response = yield call(businessLocationDetailAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(businessLocationDetailRequestSuccess(decryptedData))
            } else {
                yield put(businessLocationDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: BusinessLocationActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(businessLocationDetailRequestError(error.response.data))
        }
    }
}

export function* businessLocationDetailReq() {
    yield takeLatest(BusinessLocationActionTypes.BUSINESS_LOCATION_DETAIL_REQUEST, handleBusinessLocationDetail)
}

function* handleAddBusinessLocation({ payload }) {
    try {
        const response = yield call(addLocationAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addBusinessLocationRequestSuccess(decryptedData))
            } else {
                yield put(addBusinessLocationRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: BusinessLocationActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(addBusinessLocationRequestError(error.response.data))
        }
    }
}

export function* addBusinessLocationReq() {
    yield takeLatest(BusinessLocationActionTypes.ADD_BUSINESS_LOCATION_REQUEST, handleAddBusinessLocation)
}