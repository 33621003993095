import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCqxQsbJOVuP7EAtKUOsM7vQiI9yob3lPk",
    authDomain: "justgomolite.firebaseapp.com",
    projectId: "justgomolite",
    storageBucket: "justgomolite.appspot.com",
    messagingSenderId: "314530007430",
    appId: "1:314530007430:web:99ec7f57d88122de23c407",
    measurementId: "G-1XPETJZ23W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const analytics = getAnalytics(app);

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });