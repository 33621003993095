import React from "react";
import { ROUTES } from "../../../utils/AppConstants";
import './styles.css'

const ScreenFooter = (props) => {
    const { navigate, showForBusiness = false, showForCustomer = false,
        showBusinessLogin = false, showBusinessRegistration = false,
        showCustomerLogin = false
    } = props

    const handlePrivacyPolicyClick = () => {
        navigate(ROUTES.privacyPolicy)
    }

    const handleCookiePolicyClick = () => {
        navigate(ROUTES.cookiePolicy)
    }

    const mailToSupportClickHandler = () => {
        window.location = "mailto:support@gomobites.com"
    }

    const handleHomeClick = () => {
        navigate(ROUTES.home)
    }

    const handleViewOfferClick = () => {
        navigate(ROUTES.offerList)
    }

    const handleBusinessLoginClick = () => {
        navigate(ROUTES.vendorLogin)
    }

    const handleCustomerLoginClick = () => {
        navigate(ROUTES.customerLogin)
    }

    const handleBusinessRegistrationClick = () => {
        navigate(ROUTES.vendorCreateAccount)
    }

    const handleForBusinessClick = () => {
        navigate(ROUTES.business)
    }

    const handleForCustomerClick = () => {
        navigate(ROUTES.home)
    }

    return (
        <div className='sf-screenFooter'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-3 colElementItemCenter'>
                        <div className='sf-footer-logo-container'>
                            <img src={require('../../../assets/logo_gomint2.png')} className='img' />
                        </div>
                    </div>
                    <div className='col-sm-3 colElement'>
                        <div className="sf-colElementHeading">Links</div>
                        <div className="sf-sub-link-text" onClick={() => { handleHomeClick() }}>Home</div>
                        <div className="sf-sub-link-text" onClick={() => { handlePrivacyPolicyClick() }}>Privacy Policy</div>
                        <div className="sf-sub-link-text" onClick={() => { handleCookiePolicyClick() }}>Cookie Policy</div>
                    </div>
                    <div className='col-sm-3 colElement'>
                        <div className="sf-colElementHeading">For GoMint Users</div>
                        <div className="sf-sub-link-text" onClick={() => { handleViewOfferClick() }}>View Offers</div>
                        {showForBusiness && <div className="sf-sub-link-text" onClick={() => { handleForBusinessClick() }}>For Business</div>}
                        {showForCustomer && <div className="sf-sub-link-text" onClick={() => { handleForCustomerClick() }}>For Customer</div>}
                        {showBusinessLogin && <div className="sf-sub-link-text" onClick={() => { handleBusinessLoginClick() }}>Business Login</div>}
                        {showBusinessRegistration && <div className="sf-sub-link-text" onClick={() => { handleBusinessRegistrationClick() }}>Business Registration</div>}
                        {showCustomerLogin && <div className="sf-sub-link-text" onClick={() => { handleCustomerLoginClick() }}>Customer Login</div>}
                    </div>
                    <div className="col-sm-3 colElementCenter">
                        <div className="sf-colElementHeadingLogo">Contact</div>
                        <div className="colElement14Label" onClick={() => { mailToSupportClickHandler() }}>support@gomobites.com</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScreenFooter