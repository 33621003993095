import React from "react";
import { ROUTES } from "../../../utils/AppConstants";
import './styles.css'

const NavBarComponent = (props) => {
    const { navigate, showHome = false, showOfferList = false,
        showCustomerLogin = false, showForBusiness = false, showBusinessLogin = false,
        showBusinessRegistration = false, isWhiteBackground = false, showForCustomer = false } = props
    const TYPE_VIEW_OFFERS = 1
    const TYPE_FOR_BUSINESS = 2
    const TYPE_FOR_CUSTOMER = 3
    const TYPE_HOME = 4
    const TYPE_BUSINESS_LOGIN = 5
    const TYPE_BUSINESS_REGISTRATION = 6

    const handleItemClick = type => {
        switch (type) {
            case TYPE_VIEW_OFFERS:
                navigate(ROUTES.offerList)
                break;
            case TYPE_FOR_BUSINESS:
                navigate(ROUTES.business)
                break;

            case TYPE_FOR_CUSTOMER:
                navigate(ROUTES.customerLogin)
                break;
            case TYPE_HOME:
                navigate(ROUTES.home)
                break;
            case TYPE_BUSINESS_LOGIN:
                navigate(ROUTES.vendorLogin)
                break;
            case TYPE_BUSINESS_REGISTRATION:
                navigate(ROUTES.vendorCreateAccount)
                break;
            default:
                break;
        }
    }

    return (<nav class="navbar navbar-expand-lg" style={{ backgroundColor: isWhiteBackground ? '#fff' : '#d3ddff' }}>
        <div class="container-fluid">
            <div className="home-logo-parent-container">
                <div className="home-logo-container">
                    <img src={require('../../../assets/logo_gomint2.png')} className="img" alt="logo" />
                </div>
            </div>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-lg-0">
                </ul>
                <ul className="navbar-nav">
                    {showHome && <li class="nav-item nav-item-label">
                        <a class={`nav-link nb-item-container ${isWhiteBackground ? 'nb-item-container-white' : ''}`} aria-current="page" onClick={() => { handleItemClick(TYPE_HOME) }}>HOME</a>
                    </li>}
                    {showOfferList && <li class="nav-item nav-item-label">
                        <a class={`nav-link nb-item-container ${isWhiteBackground ? 'nb-item-container-white' : ''}`} aria-current="page" onClick={() => { handleItemClick(TYPE_VIEW_OFFERS) }}>VIEW OFFERS</a>
                    </li>}
                    {showForBusiness && <li class="nav-item nav-item-label">
                        <a class={`nav-link nb-item-container ${isWhiteBackground ? 'nb-item-container-white' : ''}`} aria-current="page" onClick={() => { handleItemClick(TYPE_FOR_BUSINESS) }}>FOR BUSINESS</a>
                    </li>}
                    {showForCustomer && <li class="nav-item nav-item-label">
                        <a class={`nav-link nb-item-container ${isWhiteBackground ? 'nb-item-container-white' : ''}`} aria-current="page" onClick={() => { handleItemClick(TYPE_HOME) }}>FOR CUSTOMERS</a>
                    </li>}
                    {showCustomerLogin && <li class="nav-item nav-item-label">
                        <a class={`nav-link nb-item-container ${isWhiteBackground ? 'nb-item-container-white' : ''}`} aria-current="page" onClick={() => { handleItemClick(TYPE_FOR_CUSTOMER) }}>CUSTOMER LOGIN</a>
                    </li>}
                    {showBusinessLogin && <li class="nav-item nav-item-label">
                        <a class={`nav-link nb-item-container ${isWhiteBackground ? 'nb-item-container-white' : ''}`} aria-current="page" onClick={() => { handleItemClick(TYPE_BUSINESS_LOGIN) }}>LOGIN</a>
                    </li>}
                    {showBusinessRegistration && <li class="nav-item nav-item-label">
                        <a class={`nav-link nb-item-container ${isWhiteBackground ? 'nb-item-container-white' : ''}`} aria-current="page" onClick={() => { handleItemClick(TYPE_BUSINESS_REGISTRATION) }}>CREATE ACCOUNT</a>
                    </li>}
                </ul>
            </div>
        </div>
    </nav>)
}

export default NavBarComponent