import React from "react";
import './styles.css'

const ScreenLabel = ({ label }) => {
    return (
        <div className="screen-label">
            {label}
        </div>
    )
}

export default ScreenLabel