import { InvoiceActionTypes } from './invoice.types'

//Add Invoice
export const addInvoiceRequest = data => ({
    type: InvoiceActionTypes.ADD_INVOICE_REQUEST,
    payload: data
})

export const addInvoiceRequestSuccess = data => ({
    type: InvoiceActionTypes.ADD_INVOICE_REQUEST_SUCCESS,
    payload: data
})

export const addInvoiceRequestError = data => ({
    type: InvoiceActionTypes.ADD_INVOICE_REQUEST_ERROR,
    payload: data
})

export const addInvoiceRequestStatus = data => ({
    type: InvoiceActionTypes.ADD_INVOICE_REQUEST_STATUS,
    payload: data
})

//Invoice List
export const invoiceListRequest = data => ({
    type: InvoiceActionTypes.INVOICE_LIST_REQUEST,
    payload: data
})

export const invoiceListRequestSuccess = data => ({
    type: InvoiceActionTypes.INVOICE_LIST_REQUEST_SUCCESS,
    payload: data
})

export const invoiceListRequestError = data => ({
    type: InvoiceActionTypes.INVOICE_LIST_REQUEST_ERROR,
    payload: data
})

//Invoice Detail
export const invoiceDetailRequest = data => ({
    type: InvoiceActionTypes.INVOICE_DETAIL_REQUEST,
    payload: data
})

export const invoiceDetailRequestSuccess = data => ({
    type: InvoiceActionTypes.INVOICE_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const invoiceDetailRequestError = data => ({
    type: InvoiceActionTypes.INVOICE_DETAIL_REQUEST_ERROR,
    payload: data
})

//Invoice Update Payment
export const invoiceUpdatePaymentRequest = data => ({
    type: InvoiceActionTypes.INVOICE_UPDATE_PAYMENT_REQUEST,
    payload: data
})

export const invoiceUpdatePaymentRequestSuccess = data => ({
    type: InvoiceActionTypes.INVOICE_UPDATE_PAYMENT_REQUEST_SUCCESS,
    payload: data
})

export const invoiceUpdatePaymentRequestError = data => ({
    type: InvoiceActionTypes.INVOICE_UPDATE_PAYMENT_REQUEST_ERROR,
    payload: data
})

export const invoiceUpdatePaymentRequestStatus = data => ({
    type: InvoiceActionTypes.INVOICE_UPDATE_PAYMENT_REQUEST_STATUS,
    payload: data
})