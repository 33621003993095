import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { customerCashbackListRequest } from '../../../redux/customer-cashback/customerCashback.action'
import Select from 'react-select'
import { displayCurrencySymbolLeft, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserCustomer, isLoggedInUserOperator, isNull, removeSpecialCharAndChangeToPascal } from "../../../utils/Utils";
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import CommonButton from "../../../components/button/common-button/commonButton";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import { addCashbackRequest, addCashbackRequestStatus } from "../../../redux/cashback/cashback.action";

const CustomerCashbackList = props => {
    const [isLoading, setLoading] = useState(false)
    const { user } = useSelector(state => state.user)
    const { customerCashbackListSuccess, customerCashbackListError } = useSelector(state => state.customerCashback)
    const { addCashbackSuccess, addCashbackError, addCashbackReqStatus } = useSelector(state => state.cashback)
    const dispatch = useDispatch()
    let navigate = useNavigate();

    useEffect(() => {
        fetchCustomerCashbackList();
    }, [])

    const fetchCustomerCashbackList = () => {
        if (isLoggedInUserCustomer(user)) {
            setLoading(true);
            const data = {
                customerId: user.data.userDetails.customerId
            };
            dispatch(customerCashbackListRequest(data));
        }
    }

    useEffect(() => {
        if (addCashbackReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addCashbackSuccess.message)
            fetchCustomerCashbackList()
        } else if (addCashbackReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addCashbackError.message)
        }
        if (addCashbackReqStatus?.length) {
            dispatch(addCashbackRequestStatus(''))
        }
    }, [addCashbackSuccess, addCashbackError, addCashbackReqStatus])

    useEffect(() => {
        if (!isNull(customerCashbackListSuccess)) {
            setLoading(false)
        } else if (!isNull(customerCashbackListError)) {
            setLoading(false)
        }
    }, [customerCashbackListSuccess, customerCashbackListError])

    const getAmountWithCurrency = (e, amount) => {
        return (displayCurrencySymbolLeft(e.currencyRegion) ?
            <span>
                {e.currencySymbol} {amount}
            </span>
            : <span>
                {amount} {e.currencySymbol}
            </span>
        )
    }

    const handleRequestRedeemClick = (item) => {
        if (parseFloat(item.cashbackAmount) > parseFloat(item.cashbackRequestAmount)) {
            setLoading(true)
            const data = {
                customerId: user.data.userDetails.customerId,
                businessId: item.businessId,
                locationId: item.locationId,
                amount: item.cashbackAmount,
                customerCashbackAmtId: item.customerCashbackAmtId,
            }
            dispatch(addCashbackRequest(data))
        } else {
            displayErrorToast('Insufficient cashback amount or request already raised for cashback.')
        }
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Cashback Rewards' />
                {!isNull(customerCashbackListSuccess) && !isEmpty(customerCashbackListSuccess.data) && <CommonFormBorder>
                    {customerCashbackListSuccess.data.map(e => (<div className="ccl-list-row-container">
                        <div className="ccl-row-business-container">
                            <div>
                                <div className="ccl-row-business-label">{e.businessDetail.businessName}</div>
                                <div className="ccl-row-loc-label">{e.locationDetail.city}, {e.locationDetail.state}, {e.locationDetail.country}</div>
                            </div>
                            <div className="ccl-row-amount-label">{getAmountWithCurrency(e, e.cashbackAmount)}</div>
                        </div>
                        {parseFloat(e.cashbackRequestAmount) > 0 && <div className="ccl-request-label">Cashback request raised for {getAmountWithCurrency(e, e.cashbackRequestAmount)} amount</div>}
                        <div className="ccl-redeem-container">
                            <div className="ccl-redeem-label" onClick={() => { handleRequestRedeemClick(e) }}>Request Redeem</div>
                        </div>
                    </div>))}
                </CommonFormBorder>}
                {!isNull(customerCashbackListError) && <div className="content-error">{customerCashbackListError.message}</div>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default CustomerCashbackList
