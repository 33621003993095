import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import { useLocation } from 'react-router-dom';
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import { displayErrorToast, displaySuccessToast, isEmpty, isNull } from "../../../utils/Utils";
import {
    operatorDetailRequest, countryListRequest, stateListRequest,
    cityListRequest, updateOperatorLocationRequest, updateOperatorLocationRequestStatus
} from '../../../redux/user/user.action'
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import moment from "moment";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import Select from 'react-select'
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";

const OperatorDetail = props => {
    const { state } = useLocation()
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { operatorDetailSuccess, operatorDetailError, countryListSuccess,
        countryListError, stateListSuccess, stateListError,
        cityListSuccess, cityListError,
        updateOperatorLocationSuccess, updateOperatorLocationError, updateOperatorLocationReqStatus } = useSelector(state => state.user)
    const [allowedLocationList, setAllowedLocationList] = useState([])
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [countryCode, setCountryCode] = useState('')

    useEffect(() => {
        if (!isEmpty(state)) {
            dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
            fetchOperatorDetails()
            dispatch(countryListRequest())
        }
    }, [])

    const fetchOperatorDetails = () => {
        setLoading(true)
        dispatch(operatorDetailRequest(state))
    }

    useEffect(() => {
        if (!isNull(operatorDetailSuccess)) {
            setLoading(false)
            setAllowedLocationList(operatorDetailSuccess.data.accessLocations)
        } else if (!isNull(operatorDetailError)) {
            setLoading(false)
            displayErrorToast(operatorDetailError.message)
        }
    }, [operatorDetailSuccess, operatorDetailError])

    useEffect(() => {
        if (updateOperatorLocationReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(updateOperatorLocationSuccess.message)
            fetchOperatorDetails()
        } else if (updateOperatorLocationReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(updateOperatorLocationError.message)
        }
        if (updateOperatorLocationReqStatus?.length) {
            dispatch(updateOperatorLocationRequestStatus(''))
        }
    }, [updateOperatorLocationSuccess, updateOperatorLocationError, updateOperatorLocationReqStatus])

    const fetchStateList = (countryId) => {
        setLoading(true)
        const data = {
            countryId: countryId
        }
        dispatch(stateListRequest(data))
    }

    const fetchCityList = (countryId, stateId) => {
        setLoading(true)
        const data = {
            countryId: countryId,
            stateId: stateId
        }
        dispatch(cityListRequest(data))
    }

    useEffect(() => {
        if (!isNull(countryListSuccess)) {
            setLoading(false)
        } else if (!isNull(countryListError)) {
            setLoading(false)
        }
    }, [countryListSuccess, countryListError])

    useEffect(() => {
        if (!isNull(stateListSuccess)) {
            setLoading(false)
        } else if (!isNull(stateListError)) {
            setLoading(false)
        }
    }, [stateListSuccess, stateListError])

    useEffect(() => {
        if (!isNull(cityListSuccess)) {
            setLoading(false)
        } else if (!isNull(cityListError)) {
            setLoading(false)
        }
    }, [cityListSuccess, cityListError])

    const handleCountrySelectChange = e => {
        setCountryDropdown(e);
        setCountryCode(e.iso2)
        setStateDropdown(null)
        setCityDropdown(null)
        fetchStateList(e.iso2)
        updateLocationList(e.name)
    }

    function updateLocationList(country, state = null, city = null) {
        setAllowedLocationList((prevList) => {
            // Create a shallow copy of the previous state
            let currentList = [...prevList];

            if (!isNull(country)) {
                const existingCountryIndex = currentList.findIndex((item) => item.country === country);

                if (existingCountryIndex === -1) {
                    // Country does not exist, create a new object and add it to the list
                    currentList.push({
                        country: country,
                        stateCity: [],
                    });
                }

                if (!isNull(state)) {
                    // Country exists, check if the state exists in the stateCity list
                    const existingStateIndex =
                        currentList[existingCountryIndex].stateCity.findIndex((item) => item.state === state);

                    if (existingStateIndex === -1) {
                        // State does not exist, add state and city in the same format for the provided country
                        currentList[existingCountryIndex].stateCity.push({
                            state: state,
                            city: [],
                        });
                    }

                    if (!isNull(city)) {
                        // State exists, check if the city exists in the city list of the state object
                        const existingCityIndex =
                            currentList[existingCountryIndex].stateCity[existingStateIndex].city?.findIndex(
                                (item) => item === city
                            );

                        if (existingCityIndex === -1) {
                            // City does not exist, add the provided city to the list
                            currentList[existingCountryIndex].stateCity[existingStateIndex].city.push(city);
                        }
                    }
                }
            }

            return currentList;
        });
    }

    function removeCountry(countryToRemove) {
        setAllowedLocationList((prevList) => {
            const updatedList = prevList.filter((item) => item.country !== countryToRemove);
            return updatedList;
        });
    }

    function removeState(countryToRemove, stateToRemove) {
        setAllowedLocationList((prevList) => {
            const updatedList = prevList.map((item) => {
                if (item.country === countryToRemove) {
                    const updatedStateCity = item.stateCity.filter((state) => state.state !== stateToRemove);
                    return { ...item, stateCity: updatedStateCity };
                }
                return item;
            });
            return updatedList;
        });
    }

    function removeCity(countryToRemove, stateToRemove, cityToRemove) {
        setAllowedLocationList((prevList) => {
            const updatedList = prevList.map((item) => {
                if (item.country === countryToRemove) {
                    const updatedStateCity = item.stateCity.map((state) => {
                        if (state.state === stateToRemove) {
                            const updatedCities = state.city.filter((city) => city !== cityToRemove);
                            return { ...state, city: updatedCities };
                        }
                        return state;
                    });
                    return { ...item, stateCity: updatedStateCity };
                }
                return item;
            });
            return updatedList;
        });
    }

    const handleUpdateDetailsClick = () => {
        if (isNull(operatorDetailSuccess)) {
            displayErrorToast('Operator details not found')
        } else if (isNull(operatorDetailSuccess.data)) {
            displayErrorToast('Operator details not found')
        } else {
            setLoading(true)
            const data = {
                userId: state,
                accessLocations: JSON.stringify(allowedLocationList)
            }
            dispatch(updateOperatorLocationRequest(data))
        }
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Details' />
                {!isNull(operatorDetailSuccess) && !isNull(operatorDetailSuccess.data) && <CommonFormBorder>
                    <div className="od-name-label">{operatorDetailSuccess.data.firstName} {operatorDetailSuccess.data.lastName}</div>
                    <FormFieldLabel className='up-bold-label' label='Email' />
                    <div className="od-text-label">{operatorDetailSuccess.data.email}</div>
                    <FormFieldLabel className='up-bold-label' label='Mobile' />
                    <div className="od-text-label">{operatorDetailSuccess.data.mobile}</div>
                    <FormFieldLabel className='up-bold-label' label='Registeration Date' />
                    <div className="od-text-label">{moment(operatorDetailSuccess.data.createdOn).utc().format('DD MMM YYYY')}</div>
                    <FormFieldLabel className='up-bold-label' label='Status' />
                    <div className="od-text-label">{operatorDetailSuccess.data.status === 1 ? 'Active' : 'In-Active'}</div>
                    <FormFieldLabel className='up-bold-label' label='Select To Update Allowed Locations' />
                    {countryListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={countryDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={countryListSuccess}
                        placeholder='Select Country'
                        onChange={e => {
                            handleCountrySelectChange(e)
                        }} />}
                    <CommonInputRow>
                        {stateListSuccess && <Select
                            menuPlacement="auto"
                            className='select-half-width'
                            value={stateDropdown}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={stateListSuccess}
                            placeholder='Select State'
                            onChange={e => {
                                setStateDropdown(e);
                                setCityDropdown(null)
                                fetchCityList(countryCode, e.iso2)
                                updateLocationList(countryDropdown.name, e.name)
                            }} />}
                        {cityListSuccess && <Select
                            menuPlacement="auto"
                            className='select-half-width'
                            value={cityDropdown}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={cityListSuccess}
                            placeholder='Select City'
                            onChange={e => {
                                setCityDropdown(e);
                                updateLocationList(countryDropdown.name, stateDropdown.name, e.name)
                            }} />}
                    </CommonInputRow>
                    <FormFieldLabel className='up-bold-label' label='Allowed Locations' />
                    {!isEmpty(allowedLocationList) && <div className="ao-location-container">
                        {allowedLocationList.map(e => (
                            <div className="ao-location-country-container">
                                <div className="ao-location-country-content-container">
                                    <div className="ao-location-country-label">{e.country}</div>
                                    {!isEmpty(e.stateCity) && <div>
                                        {e.stateCity.map(e1 => (
                                            <div className="ao-location-country-state-container">
                                                <div className="ao-location-remove-state-icon" onClick={() => { removeState(e.country, e1.state) }}>
                                                    <img src={require('../../../assets/ic_delete_blue.png')} className="img" />
                                                </div>
                                                <div className="ao-location-country-state-content-container">
                                                    <div className="ao-location-state-label">{e1.state}</div>
                                                    {!isEmpty(e1.city) && <div className="ao-location-city-container">
                                                        {e1.city.map(e2 => (
                                                            <div className="ao-location-city-label">
                                                                <div>{e2}</div>
                                                                <div className="ao-location-remove-country-icon" onClick={() => { removeCity(e.country, e1.state, e2) }}>
                                                                    <img src={require('../../../assets/ic_delete_blue.png')} className="img" />
                                                                </div>
                                                            </div>

                                                        ))}
                                                    </div>}
                                                </div>

                                            </div>
                                        ))}
                                    </div>}
                                </div>
                                <div className="ao-location-remove-country-icon" onClick={() => { removeCountry(e.country) }}>
                                    <img src={require('../../../assets/ic_delete_blue.png')} className="img" />
                                </div>
                            </div>
                        ))}
                    </div>}
                    {isEmpty(allowedLocationList) && <div className="content-error">No location allowed</div>}
                    <ButtonRow>
                        <CommonButton label='Update Details'
                            onClick={() => { handleUpdateDetailsClick() }} />
                    </ButtonRow>
                </CommonFormBorder>}

            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default OperatorDetail