export const CATEGORIES = [
    { name: 'Car & Automotive', categoryKey: 'CARANDAUTOMOTIVE', categoryId: 1 },
    { name: 'Fuel', categoryKey: 'FUEL', categoryId: 2 },
    { name: 'Baby & Kids', categoryKey: 'BABYANDKIDS', categoryId: 3 },
    { name: 'Banking', categoryKey: 'BANKING', categoryId: 4 },
    { name: 'Business', categoryKey: 'BUSINESS', categoryId: 5 },
    { name: 'Clothing', categoryKey: 'CLOTHING', categoryId: 6 },
    { name: 'Fashion Accessories', categoryKey: 'FASHIONACCESSORIES', categoryId: 7 },
    { name: 'Department Stores', categoryKey: 'DEPARTMENTSTORES', categoryId: 8 },
    { name: 'Electronics', categoryKey: 'ELECTRONICS', categoryId: 9 },
    { name: 'Financial', categoryKey: 'FINANCIAL', categoryId: 10 },
    { name: 'Food & Drink', categoryKey: 'FOODANDDRINK', categoryId: 11 },
    { name: 'Gifts', categoryKey: 'GIFTS', categoryId: 12 },
    { name: 'Health & Beauty', categoryKey: 'HEALTHANDBEAUTY', categoryId: 13 },
    { name: 'Home & Garden', categoryKey: 'HOMEGARDEN', categoryId: 14 },
    { name: 'Jewelry & Watches', categoryKey: 'JEWELRYANDWATCHES', categoryId: 15 },
    { name: 'Entertainment', categoryKey: 'ENTERTAINMENT', categoryId: 16 },
    { name: 'Sports & Fitness', categoryKey: 'SPORTSANDFITNESS', categoryId: 17 },
    { name: 'Travel', categoryKey: 'TRAVEL', categoryId: 18 },
    { name: 'Utilities & Services', categoryKey: 'UTILITIESANDSERVICES', categoryId: 19 }
]