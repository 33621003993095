export const BusinessActionTypes = {
    LOGOUT: 'LOGOUT',
    COMMON_ERROR: 'COMMON_ERROR',

    ADD_BUSINESS_REQUEST: 'ADD_BUSINESS_REQUEST',
    ADD_BUSINESS_REQUEST_SUCCESS: 'ADD_BUSINESS_REQUEST_SUCCESS',
    ADD_BUSINESS_REQUEST_ERROR: 'ADD_BUSINESS_REQUEST_ERROR',
    ADD_BUSINESS_REQUEST_STATUS: 'ADD_BUSINESS_REQUEST_STATUS',

    USER_BUSINESS_LIST_REQUEST: 'USER_BUSINESS_LIST_REQUEST',
    USER_BUSINESS_LIST_REQUEST_SUCCESS: 'USER_BUSINESS_LIST_REQUEST_SUCCESS',
    USER_BUSINESS_LIST_REQUEST_ERROR: 'USER_BUSINESS_LIST_REQUEST_ERROR',

    BUSINESS_DETAIL_REQUEST: 'BUSINESS_DETAIL_REQUEST',
    BUSINESS_DETAIL_REQUEST_SUCCESS: 'BUSINESS_DETAIL_REQUEST_SUCCESS',
    BUSINESS_DETAIL_REQUEST_ERROR: 'BUSINESS_DETAIL_REQUEST_ERROR',

    UPDATE_BUSINESS_REQUEST: 'UPDATE_BUSINESS_REQUEST',
    UPDATE_BUSINESS_REQUEST_SUCCESS: 'UPDATE_BUSINESS_REQUEST_SUCCESS',
    UPDATE_BUSINESS_REQUEST_ERROR: 'UPDATE_BUSINESS_REQUEST_ERROR',
    UPDATE_BUSINESS_REQUEST_STATUS: 'UPDATE_BUSINESS_REQUEST_STATUS'
}
