import React from 'react'
import './loader.styles.scss'
import { ReactComponent as LoadingIcon } from '../../assets/spin_clock.svg'

const Loader = ({ active, text }) => {
    return (
        active ?
            <div className='loader'>
                <LoadingIcon className='icon' />
                <div className='loader-text'>{text}</div>
            </div>
            : null
    )

}

export default Loader