export const CashbackActionTypes = {
    LOGOUT: 'LOGOUT',

    ADD_CASHBACK_REQUEST: 'ADD_CASHBACK_REQUEST',
    ADD_CASHBACK_REQUEST_SUCCESS: 'ADD_CASHBACK_REQUEST_SUCCESS',
    ADD_CASHBACK_REQUEST_ERROR: 'ADD_CASHBACK_REQUEST_ERROR',
    ADD_CASHBACK_REQUEST_STATUS: 'ADD_CASHBACK_REQUEST_STATUS',

    CASHBACK_REQUEST_LIST_REQUEST: 'CASHBACK_REQUEST_LIST_REQUEST',
    CASHBACK_REQUEST_LIST_REQUEST_SUCCESS: 'CASHBACK_REQUEST_LIST_REQUEST_SUCCESS',
    CASHBACK_REQUEST_LIST_REQUEST_ERROR: 'CASHBACK_REQUEST_LIST_REQUEST_ERROR',

    CASHBACK_UPDATE_STATUS_REQUEST: 'CASHBACK_UPDATE_STATUS_REQUEST',
    CASHBACK_UPDATE_STATUS_REQUEST_SUCCESS: 'CASHBACK_UPDATE_STATUS_REQUEST_SUCCESS',
    CASHBACK_UPDATE_STATUS_REQUEST_ERROR: 'CASHBACK_UPDATE_STATUS_REQUEST_ERROR',
    CASHBACK_UPDATE_STATUS_REQUEST_STATUS: 'CASHBACK_UPDATE_STATUS_REQUEST_STATUS',

    COMMON_ERROR: 'COMMON_ERROR',
}
