import { InvoiceActionTypes } from './invoice.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addInvoiceSuccess: null,
    addInvoiceError: null,
    addInvoiceReqStatus: '',
    invoiceListSuccess: null,
    invoiceListError: null,
    invoiceDetailSuccess: null,
    invoiceDetailError: null,
    invoiceUpdatePaymentSuccess: null,
    invoiceUpdatePaymentError: null,
    invoiceUpdatePaymentReqStatus: ''
}

const invoiceReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case InvoiceActionTypes.ADD_INVOICE_REQUEST_SUCCESS:
            return {
                ...state,
                addInvoiceSuccess: action.payload,
                addInvoiceError: null,
                addInvoiceReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case InvoiceActionTypes.ADD_INVOICE_REQUEST_ERROR:
            return {
                ...state,
                addInvoiceSuccess: null,
                addInvoiceError: action.payload,
                addInvoiceReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case InvoiceActionTypes.ADD_INVOICE_REQUEST_STATUS:
            return {
                ...state,
                addInvoiceReqStatus: ''
            }
        case InvoiceActionTypes.INVOICE_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                invoiceListSuccess: action.payload,
                invoiceListError: null
            }
        case InvoiceActionTypes.INVOICE_LIST_REQUEST_ERROR:
            return {
                ...state,
                invoiceListSuccess: null,
                invoiceListError: action.payload
            }
        case InvoiceActionTypes.INVOICE_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                invoiceDetailSuccess: action.payload,
                invoiceDetailError: null
            }
        case InvoiceActionTypes.INVOICE_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                invoiceDetailSuccess: null,
                invoiceDetailError: action.payload
            }
        case InvoiceActionTypes.INVOICE_UPDATE_PAYMENT_REQUEST_SUCCESS:
            return {
                ...state,
                invoiceUpdatePaymentSuccess: action.payload,
                invoiceUpdatePaymentError: null,
                invoiceUpdatePaymentReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case InvoiceActionTypes.INVOICE_UPDATE_PAYMENT_REQUEST_ERROR:
            return {
                ...state,
                invoiceUpdatePaymentSuccess: null,
                invoiceUpdatePaymentError: action.payload,
                invoiceUpdatePaymentReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case InvoiceActionTypes.INVOICE_UPDATE_PAYMENT_REQUEST_STATUS:
            return {
                ...state,
                invoiceUpdatePaymentReqStatus: ''
            }
        case InvoiceActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default invoiceReducer