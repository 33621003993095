import React from "react";
import './styles.css'

const FormFieldLabel = ({ label, className }) => {
    return (
        <div className={`form-field-label ${className ? className : ''}`}>
            {label}
        </div>
    )
}

export default FormFieldLabel