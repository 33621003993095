import { CustomerCashbackActionTypes } from './customerCashback.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    customerCashbackListSuccess: null,
    customerCashbackListError: null,
    addCustomerCashbackSuccess: null,
    addCustomerCashbackError: null,
    addCustomerCashbackReqStatus: ''
}

const customerCashbackReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CustomerCashbackActionTypes.CUSTOMER_CASHBACK_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                customerCashbackListSuccess: action.payload,
                customerCashbackListError: null
            }
        case CustomerCashbackActionTypes.CUSTOMER_CASHBACK_LIST_REQUEST_ERROR:
            return {
                ...state,
                customerCashbackListSuccess: null,
                customerCashbackListError: action.payload
            }
        case CustomerCashbackActionTypes.ADD_CUSTOMER_CASHBACK_REQUEST_SUCCESS:
            return {
                ...state,
                addCustomerCashbackSuccess: action.payload,
                addCustomerCashbackError: null,
                addCustomerCashbackReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case CustomerCashbackActionTypes.ADD_CUSTOMER_CASHBACK_REQUEST_ERROR:
            return {
                ...state,
                addCustomerCashbackSuccess: null,
                addCustomerCashbackError: action.payload,
                addCustomerCashbackReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case CustomerCashbackActionTypes.ADD_CUSTOMER_CASHBACK_REQUEST_STATUS:
            return {
                ...state,
                addCustomerCashbackReqStatus: ''
            }
        case CustomerCashbackActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default customerCashbackReducer