import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addCashbackRequestAPI, cashbackRequestListAPI, cashbackUpdateStatusAPI
} from '../api-requests/cashbackRequest';
import {
    addCashbackRequestError, addCashbackRequestSuccess, cashbackListRequestError,
    cashbackListRequestSuccess, cashbackUpdateStatusRequestError, cashbackUpdateStatusRequestSuccess
} from './cashback.action';
import { CashbackActionTypes } from './cashback.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleCashbackRequestUpdatePayment({ payload }) {
    try {
        const response = yield call(cashbackUpdateStatusAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(cashbackUpdateStatusRequestSuccess(decryptedData))
            } else {
                yield put(cashbackUpdateStatusRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: CashbackActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(cashbackUpdateStatusRequestError(error.response.data))
        }
    }
}

export function* cashbackRequestUpdateStatusReq() {
    yield takeLatest(CashbackActionTypes.CASHBACK_UPDATE_STATUS_REQUEST, handleCashbackRequestUpdatePayment)
}

function* handleCashbackRequestList({ payload }) {
    try {
        const response = yield call(cashbackRequestListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(cashbackListRequestSuccess(decryptedData))
            } else {
                yield put(cashbackListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: CashbackActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(cashbackListRequestError(error.response.data))
        }
    }
}

export function* cashbackRequestListReq() {
    yield takeLatest(CashbackActionTypes.CASHBACK_REQUEST_LIST_REQUEST, handleCashbackRequestList)
}

function* handleAddCashbackRequest({ payload }) {
    try {
        const response = yield call(addCashbackRequestAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addCashbackRequestSuccess(decryptedData))
            } else {
                yield put(addCashbackRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: CashbackActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(addCashbackRequestError(error.response.data))
        }
    }
}

export function* addCashbackRequestReq() {
    yield takeLatest(CashbackActionTypes.ADD_CASHBACK_REQUEST, handleAddCashbackRequest)
}