import React from "react";
import './styles.css'

const TopButton = ({ onClick, label, isInverted = false, isSmall = false }) => {
    return (
        <button
            type='button'
            onClick={onClick}
            className={isInverted === false ?
                isSmall === false ? 'btn top-btn' : 'btn top-btn top-btn-sm' :
                'btn top-inverted-btn'}>
            {label}
        </button>)
}

export default TopButton