export const CustomerActionTypes = {
    LOGOUT: 'LOGOUT',

    CUSTOMER_LIST_REQUEST: 'CUSTOMER_LIST_REQUEST',
    CUSTOMER_LIST_REQUEST_SUCCESS: 'CUSTOMER_LIST_REQUEST_SUCCESS',
    CUSTOMER_LIST_REQUEST_ERROR: 'CUSTOMER_LIST_REQUEST_ERROR',

    CUSTOMER_DETAIL_REQUEST: 'CUSTOMER_DETAIL_REQUEST',
    CUSTOMER_DETAIL_REQUEST_SUCCESS: 'CUSTOMER_DETAIL_REQUEST_SUCCESS',
    CUSTOMER_DETAIL_REQUEST_ERROR: 'CUSTOMER_DETAIL_REQUEST_ERROR',

    CUSTOMER_UPDATE_INFO_REQUEST: 'CUSTOMER_UPDATE_INFO_REQUEST',
    CUSTOMER_UPDATE_INFO_REQUEST_SUCCESS: 'CUSTOMER_UPDATE_INFO_REQUEST_SUCCESS',
    CUSTOMER_UPDATE_INFO_REQUEST_ERROR: 'CUSTOMER_UPDATE_INFO_REQUEST_ERROR',
    CUSTOMER_UPDATE_INFO_REQUEST_STATUS: 'CUSTOMER_UPDATE_INFO_REQUEST_STATUS',

    CUSTOMER_ADD_BANK_DETAIL_REQUEST: 'CUSTOMER_ADD_BANK_DETAIL_REQUEST',
    CUSTOMER_ADD_BANK_DETAIL_REQUEST_SUCCESS: 'CUSTOMER_ADD_BANK_DETAIL_REQUEST_SUCCESS',
    CUSTOMER_ADD_BANK_DETAIL_REQUEST_ERROR: 'CUSTOMER_ADD_BANK_DETAIL_REQUEST_ERROR',
    CUSTOMER_ADD_BANK_DETAIL_REQUEST_STATUS: 'CUSTOMER_ADD_BANK_DETAIL_REQUEST_STATUS',

    COMMON_ERROR: 'COMMON_ERROR',

    CUSTOMER_UPDATE_BANK_DETAILS_REQUEST: 'CUSTOMER_UPDATE_BANK_DETAILS_REQUEST',
    CUSTOMER_UPDATE_BANK_DETAILS_REQUEST_SUCCESS: 'CUSTOMER_UPDATE_BANK_DETAILS_REQUEST_SUCCESS',
    CUSTOMER_UPDATE_BANK_DETAILS_REQUEST_ERROR: 'CUSTOMER_UPDATE_BANK_DETAILS_REQUEST_ERROR',
    CUSTOMER_UPDATE_BANK_DETAILS_REQUEST_STATUS: 'CUSTOMER_UPDATE_BANK_DETAILS_REQUEST_STATUS',

    SEARCH_OR_ADD_CUSTOMER_REQUEST: 'SEARCH_OR_ADD_CUSTOMER_REQUEST',
    SEARCH_OR_ADD_CUSTOMER_REQUEST_SUCCESS: 'SEARCH_OR_ADD_CUSTOMER_REQUEST_SUCCESS',
    SEARCH_OR_ADD_CUSTOMER_REQUEST_ERROR: 'SEARCH_OR_ADD_CUSTOMER_REQUEST_ERROR'
}
