import { BusinessActionTypes } from './business.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addBusinessSuccess: null,
    addBusinessError: null,
    addBusinessReqStatus: '',
    userBusinessListSuccess: null,
    userBusinessListError: null,
    businessDetailSuccess: null,
    businessDetailError: null,
    updateBusinessDetailSuccess: null,
    updateBusinessDetailError: null,
    updateBusinessDetailReqStatus: ''
}

const businessReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BusinessActionTypes.ADD_BUSINESS_REQUEST_SUCCESS:
            return {
                ...state,
                addBusinessSuccess: action.payload,
                addBusinessError: null,
                addBusinessReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case BusinessActionTypes.ADD_BUSINESS_REQUEST_ERROR:
            return {
                ...state,
                addBusinessSuccess: null,
                addBusinessError: action.payload,
                addBusinessReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case BusinessActionTypes.ADD_BUSINESS_REQUEST_STATUS:
            return {
                ...state,
                addBusinessReqStatus: ''
            }
        case BusinessActionTypes.USER_BUSINESS_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                userBusinessListSuccess: action.payload,
                userBusinessListError: null
            }
        case BusinessActionTypes.USER_BUSINESS_LIST_REQUEST_ERROR:
            return {
                ...state,
                userBusinessListSuccess: null,
                userBusinessListError: action.payload
            }
        case BusinessActionTypes.BUSINESS_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                businessDetailSuccess: action.payload,
                businessDetailError: null
            }
        case BusinessActionTypes.BUSINESS_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                businessDetailSuccess: null,
                businessDetailError: action.payload
            }
        case BusinessActionTypes.UPDATE_BUSINESS_REQUEST_SUCCESS:
            return {
                ...state,
                updateBusinessDetailSuccess: action.payload,
                updateBusinessDetailError: null,
                updateBusinessDetailReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case BusinessActionTypes.UPDATE_BUSINESS_REQUEST_ERROR:
            return {
                ...state,
                updateBusinessDetailSuccess: null,
                updateBusinessDetailError: action.payload,
                updateBusinessDetailReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case BusinessActionTypes.UPDATE_BUSINESS_REQUEST_STATUS:
            return {
                ...state,
                updateBusinessDetailReqStatus: ''
            }
        case BusinessActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default businessReducer