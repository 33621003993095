import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { ROUTES } from '../../utils/AppConstants'

const RouteGuard = ({ children }) => {
    const isLogin = useSelector((state) => state.user.isLogin)
    return (
        isLogin === true ? children : <Navigate to={ROUTES.home} />
    )
}

export default RouteGuard