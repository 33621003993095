import { CustomerActionTypes } from './customer.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    customerListSuccess: null,
    customerListError: null,
    customerDetailSuccess: null,
    customerDetailError: null,
    customerUpdateInfoSuccess: null,
    customerUpdateInfoError: null,
    customerUpdateInfoReqStatus: '',
    customerAddBankDetailsSuccess: null,
    customerAddBankDetailsError: null,
    customerAddBankDetailsReqStatus: '',
    customerUpdateBankDetailsSuccess: null,
    customerUpdateBankDetailsError: null,
    customerUpdateBankDetailsReqStatus: '',
    searchOrAddCustomerSuccess: null,
    searchOrAddCustomerError: null
}

const customerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CustomerActionTypes.CUSTOMER_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                customerListSuccess: action.payload,
                customerListError: null
            }
        case CustomerActionTypes.CUSTOMER_LIST_REQUEST_ERROR:
            return {
                ...state,
                customerListSuccess: null,
                customerListError: action.payload
            }
        case CustomerActionTypes.CUSTOMER_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                customerDetailSuccess: action.payload,
                customerDetailError: null
            }
        case CustomerActionTypes.CUSTOMER_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                customerDetailSuccess: null,
                customerDetailError: action.payload
            }
        case CustomerActionTypes.CUSTOMER_UPDATE_INFO_REQUEST_SUCCESS:
            return {
                ...state,
                customerUpdateInfoSuccess: action.payload,
                customerUpdateInfoError: null,
                customerUpdateInfoReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case CustomerActionTypes.CUSTOMER_UPDATE_INFO_REQUEST_ERROR:
            return {
                ...state,
                customerUpdateInfoSuccess: null,
                customerUpdateInfoError: action.payload,
                customerUpdateInfoReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case CustomerActionTypes.CUSTOMER_UPDATE_INFO_REQUEST_STATUS:
            return {
                ...state,
                customerUpdateInfoReqStatus: ''
            }
        case CustomerActionTypes.CUSTOMER_ADD_BANK_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                customerAddBankDetailsSuccess: action.payload,
                customerAddBankDetailsError: null,
                customerAddBankDetailsReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case CustomerActionTypes.CUSTOMER_ADD_BANK_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                customerAddBankDetailsSuccess: null,
                customerAddBankDetailsError: action.payload,
                customerAddBankDetailsReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case CustomerActionTypes.CUSTOMER_ADD_BANK_DETAIL_REQUEST_STATUS:
            return {
                ...state,
                customerAddBankDetailsReqStatus: ''
            }
        case CustomerActionTypes.CUSTOMER_UPDATE_BANK_DETAILS_REQUEST_SUCCESS:
            return {
                ...state,
                customerUpdateBankDetailsSuccess: action.payload,
                customerUpdateBankDetailsError: null,
                customerUpdateBankDetailsReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case CustomerActionTypes.CUSTOMER_UPDATE_BANK_DETAILS_REQUEST_ERROR:
            return {
                ...state,
                customerUpdateBankDetailsSuccess: null,
                customerUpdateBankDetailsError: action.payload,
                customerUpdateBankDetailsReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case CustomerActionTypes.CUSTOMER_UPDATE_BANK_DETAILS_REQUEST_STATUS:
            return {
                ...state,
                customerUpdateBankDetailsReqStatus: ''
            }
        case CustomerActionTypes.SEARCH_OR_ADD_CUSTOMER_REQUEST_SUCCESS:
            return {
                ...state,
                searchOrAddCustomerSuccess: action.payload,
                searchOrAddCustomerError: null
            }
        case CustomerActionTypes.SEARCH_OR_ADD_CUSTOMER_REQUEST_ERROR:
            return {
                ...state,
                searchOrAddCustomerSuccess: null,
                searchOrAddCustomerError: action.payload
            }
        case CustomerActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default customerReducer