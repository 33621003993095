import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { cashbackListRequest, cashbackUpdateStatusRequest, cashbackUpdateStatusRequestStatus } from '../../../redux/cashback/cashback.action'
import Select from 'react-select'
import { displayCurrencySymbolLeft, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserCustomer, isLoggedInUserOperator, isNull, removeSpecialCharAndChangeToPascal } from "../../../utils/Utils";
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import CommonButton from "../../../components/button/common-button/commonButton";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";

const CashbackRequestList = props => {
    const [isLoading, setLoading] = useState(false)
    const { user } = useSelector(state => state.user)
    const { cashbackListSuccess, cashbackListError,
        cashbackUpdatePaymentSuccess, cashbackUpdatePaymentError, cashbackUpdatePaymentReqStatus } = useSelector(state => state.cashback)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [selectedRequest, setSelectedRequest] = useState(null)
    const [displayDetailModal, setDisplayDetailModal] = useState(false)
    const CASHBACK_REQUEST_STATUS_LIST = [
        { key: APP_CONSTANTS.CASHBACK_REQUEST_STATUS.IN_PROCESS, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CASHBACK_REQUEST_STATUS.IN_PROCESS) },
        { key: APP_CONSTANTS.CASHBACK_REQUEST_STATUS.PAID, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CASHBACK_REQUEST_STATUS.PAID) },
        { key: APP_CONSTANTS.CASHBACK_REQUEST_STATUS.ERROR, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CASHBACK_REQUEST_STATUS.ERROR) },
        { key: APP_CONSTANTS.CASHBACK_REQUEST_STATUS.REJECTED, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CASHBACK_REQUEST_STATUS.REJECTED) }
    ]
    const FILTER_CASHBACK_REQUEST_STATUS_LIST = [
        { key: APP_CONSTANTS.CASHBACK_REQUEST_STATUS.PENDING, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CASHBACK_REQUEST_STATUS.PENDING) },
        { key: APP_CONSTANTS.CASHBACK_REQUEST_STATUS.IN_PROCESS, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CASHBACK_REQUEST_STATUS.IN_PROCESS) },
        { key: APP_CONSTANTS.CASHBACK_REQUEST_STATUS.PAID, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CASHBACK_REQUEST_STATUS.PAID) },
        { key: APP_CONSTANTS.CASHBACK_REQUEST_STATUS.ERROR, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CASHBACK_REQUEST_STATUS.ERROR) },
        { key: APP_CONSTANTS.CASHBACK_REQUEST_STATUS.REJECTED, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CASHBACK_REQUEST_STATUS.REJECTED) }
    ]
    const [requestStatusDropdown, setRequestStatusDropdown] = useState()
    const [displayErrorInput, setDisplayErrorInput] = useState(false)
    const [errorInput, setErrorInput] = useState('')
    const [displayRequestStatusFilter, setDisplayRequestStatusFilter] = useState(false)
    const [countryDropdown, setCountryDropdown] = useState();
    const [requestStatusFilterDropdown, setRequestStatusFilterDropdown] = useState()
    const [displayRequestList, setDisplayRequestList] = useState(false)

    useEffect(() => {
        if (isLoggedInUserCustomer(user)) {
            setDisplayRequestStatusFilter(true)
        }
    }, [])

    const fetchRequestList = () => {
        setLoading(true)
        const data = {
            requestStatus: requestStatusFilterDropdown.key
        }
        if (isLoggedInUserCustomer(user)) {
            data.customerId = user.data.userDetails.customerId
        }
        if (isLoggedInUserOperator(user)) {
            data.country = countryDropdown.name
        }
        dispatch(cashbackListRequest(data))
    }

    useEffect(() => {
        if (cashbackUpdatePaymentReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(cashbackUpdatePaymentSuccess.message)
            fetchRequestList()
        } else if (cashbackUpdatePaymentReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(cashbackUpdatePaymentError.message)
        }
        if (cashbackUpdatePaymentReqStatus.length) {
            dispatch(cashbackUpdateStatusRequestStatus(''))
        }
    }, [cashbackUpdatePaymentSuccess, cashbackUpdatePaymentError, cashbackUpdatePaymentReqStatus])

    useEffect(() => {
        if (!isNull(cashbackListSuccess)) {
            setLoading(false)
        } else if (!isNull(cashbackListError)) {
            setDisplayRequestList(false)
            setLoading(false)
        }
    }, [cashbackListSuccess, cashbackListError])

    const getAmountWithCurrency = (e, amount) => {
        return (displayCurrencySymbolLeft(e.currencyRegion) ?
            <span>
                {e.currencySymbol} {amount}
            </span>
            : <span>
                {amount} {e.currencySymbol}
            </span>
        )
    }

    const handleCardClick = e => {
        setSelectedRequest(e)
        setDisplayDetailModal(true)
    }

    const handleUpdateStatusClick = () => {
        if (isEmpty(selectedRequest)) {
            displayErrorToast('Request not selected')
        } else if (isEmpty(requestStatusDropdown)) {
            displayErrorToast('Request status not selected')
        } else if (displayErrorInput === true && isEmpty(errorInput)) {
            displayErrorToast('Request reason cannot be blank')
        } else {
            setLoading(true)
            const data = {
                cashbackRequestId: selectedRequest.cashbackRequestId,
                status: requestStatusDropdown.key
            }
            if (displayErrorInput === true) {
                data.reason = errorInput
            }
            dispatch(cashbackUpdateStatusRequest(data))
            setDisplayDetailModal(false)
        }
    }

    useEffect(() => {
        if (!isNull(requestStatusFilterDropdown)) {
            setDisplayRequestList(true)
            fetchRequestList()
        } else {
            setDisplayRequestList(false)
        }
    }, [requestStatusFilterDropdown])

    const getCustomerName = item => {
        if (isEmpty(item.customerDetail.firstName) && isEmpty(item.customerDetail.lastName)) {
            return `+${item.customerDetail.mobile}`
        } else {
            return `${item.customerDetail.firstName} ${item.customerDetail.lastName}`
        }
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Cashback Requests' />
                {isLoggedInUserOperator(user) && <div>
                    <FormFieldLabel className='up-bold-label' label='Select Location' />
                    {isEmpty(user.data.userDetails.accessLocations) && <div>No location access provided. Kindly request access to view receipts</div>}
                    {!isEmpty(user.data.userDetails.accessLocations) &&
                        <Select
                            menuPlacement="auto"
                            className='select-full-width-no-border'
                            value={countryDropdown}
                            getOptionLabel={e => e.country}
                            getOptionValue={e => e}
                            options={user.data.userDetails.accessLocations}
                            placeholder='Select Country'
                            onChange={e => {
                                setCountryDropdown(e)
                                setDisplayRequestStatusFilter(true)
                            }} />
                    }
                </div>}
                {displayRequestStatusFilter && <div>
                    <FormFieldLabel className='up-bold-label' label='Select Request Type' />
                    <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={requestStatusFilterDropdown}
                        getOptionLabel={e => e.value}
                        getOptionValue={e => e}
                        options={FILTER_CASHBACK_REQUEST_STATUS_LIST}
                        placeholder='Select Request Type'
                        onChange={e => {
                            setRequestStatusFilterDropdown(e)
                        }} />
                </div>}
                {displayRequestList === true && !isNull(cashbackListSuccess) && <div className="table-scroll-container">
                    {!isNull(cashbackListSuccess.data) && <table class="table table-hover margin-top-10">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">Request Number</th>
                                <th scope="col" className="rl-table-col-title text-center">Amount</th>
                                <th scope="col" className="rl-table-col-title text-center">Status</th>
                                {isLoggedInUserOperator(user) && <th scope="col" className="rl-table-col-title text-center">Customer</th>}
                                <th scope="col" className="rl-table-col-title text-center">Request Date</th>
                                <th scope="col" className="rl-table-col-title text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cashbackListSuccess.data.map(element => (
                                <tr className="rl-table-row-container">
                                    <td className="rl-table-col-item">{element.cashbackRequestId}</td>
                                    <td className="rl-table-col-item text-center">{getAmountWithCurrency(element, element.requestAmount)}</td>
                                    <td className="rl-table-col-item text-center">{removeSpecialCharAndChangeToPascal(element.requestStatus)}</td>
                                    {isLoggedInUserOperator(user) && <td className='rl-table-col-item text-center'>{`${!isNull(element.customerDetail) ? `${getCustomerName(element)}` : '-'} `}</td>}
                                    <td className="rl-table-col-item text-center">{moment(element.createdOn).utc().format('DD MMM YYYY')}</td>
                                    <td className="rl-table-col-item text-center" >
                                        <div className="resl-btn-container" onClick={() => {
                                            handleCardClick(element)
                                        }}>View Details</div></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>}
                {!isNull(cashbackListError) && <div className="content-error">{cashbackListError.message}</div>}
            </CommonScreenContent>
            <Modal
                onHide={() => { setDisplayDetailModal(false) }}
                show={displayDetailModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Request Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!isNull(selectedRequest) && <div>
                        <div className='form-field-label-grey'>Request Number</div>
                        <div className="form-field-text-label">{selectedRequest.cashbackRequestId}</div>
                        <div className='form-field-label-grey'>Amount</div>
                        <div className="form-field-text-label">{getAmountWithCurrency(selectedRequest, selectedRequest.requestAmount)}</div>
                        <div className='form-field-label-grey'>Request Status</div>
                        <div className="form-field-text-label">{removeSpecialCharAndChangeToPascal(selectedRequest.requestStatus)}</div>
                        {selectedRequest.requestStatus === APP_CONSTANTS.CASHBACK_REQUEST_STATUS.ERROR && <div>
                            <div className='form-field-label-grey'>Status Reason</div>
                            <div className="form-field-text-label">{removeSpecialCharAndChangeToPascal(selectedRequest.rejectedReason)}</div>
                        </div>}
                        <div className='form-field-label-grey'>Location</div>
                        <div className="form-field-text-label">{selectedRequest.state}, {selectedRequest.country}</div>
                        {isLoggedInUserOperator(user) && <div>
                            {!isNull(selectedRequest.customerDetail) && <div>
                                <FormFieldLabel label='Customer Information' />
                                <div className='form-field-label-grey'>Name</div>
                                <div className="form-field-text-label">{selectedRequest.customerDetail.firstName} {selectedRequest.customerDetail.lastName}</div>
                                <div className='form-field-label-grey'>Mobile</div>
                                <div className="form-field-text-label">{selectedRequest.customerDetail.mobile}</div>
                            </div>}
                            {!isEmpty(selectedRequest.bankDetail) && <div>
                                <FormFieldLabel label='Bank Details' />
                                {selectedRequest.bankDetail.map(e => (
                                    <div className="cp-cashback-row-container">
                                        <div className="cp-country-label">{e.bankName}</div>
                                        <div className="cp-name-label">A/c Holder: {e.accountHolderName}</div>
                                        <div className="cp-currency-label">{e.accountNumber}</div>
                                        <div className="cp-name-label">IFSC Code: {e.ifscCode}</div>
                                        <div className="cp-country-label">Location: {e.city}, {e.state}, {e.country}</div>
                                    </div>
                                ))}
                            </div>}

                            {(selectedRequest.requestStatus === APP_CONSTANTS.CASHBACK_REQUEST_STATUS.PENDING || selectedRequest.requestStatus === APP_CONSTANTS.CASHBACK_REQUEST_STATUS.IN_PROCESS) && <div>
                                <FormFieldLabel label='Update Status' />
                                <Select
                                    menuPlacement="auto"
                                    className='select-full-width-no-border'
                                    value={requestStatusDropdown}
                                    getOptionLabel={e => e.value}
                                    getOptionValue={e => e}
                                    options={CASHBACK_REQUEST_STATUS_LIST}
                                    placeholder='Select Status'
                                    onChange={e => {
                                        setRequestStatusDropdown(e)
                                        if (e.key === APP_CONSTANTS.CASHBACK_REQUEST_STATUS.ERROR ||
                                            e.key === APP_CONSTANTS.CASHBACK_REQUEST_STATUS.REJECTED) {
                                            setDisplayErrorInput(true)
                                        } else {
                                            setDisplayErrorInput(false)
                                        }
                                    }} />
                                {displayErrorInput === true && <CommonInputFloatFull
                                    type='text'
                                    id='errorInput'
                                    onChange={e => setErrorInput(e.target.value)}
                                    placeholder='Reason'
                                    value={errorInput}
                                />}
                            </div>}

                        </div>}
                    </div>}
                </Modal.Body>
                <Modal.Footer>
                    {isLoggedInUserOperator(user) && !isNull(selectedRequest) && selectedRequest.requestStatus === APP_CONSTANTS.CASHBACK_REQUEST_STATUS.PENDING && <CommonButton label='Update Status'
                        onClick={() => { handleUpdateStatusClick() }} />}
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default CashbackRequestList