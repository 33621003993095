export const CurrencyLabel = [
    {
        "currency": "Bulgarian lev",
        "code": "BGN",
        "symbol": "лв",
        "region": "Europe",
        "country": ["Bulgaria"]
    },
    {
        "currency": "Swiss franc",
        "code": "CHF",
        "symbol": "CHF",
        "region": "Europe",
        "country": ["Switzerland", "Liechtenstein"]
    },
    {
        "currency": "Czech koruna",
        "code": "CZK",
        "symbol": "Kč",
        "region": "Europe",
        "country": ["Czech Republic"]
    },
    {
        "currency": "Danish krone",
        "code": "DKK",
        "symbol": "kr",
        "region": "Europe",
        "country": ["Denmark", "Greenland"]
    },
    {
        "currency": "Euro",
        "code": "EUR",
        "symbol": "€",
        "region": "Europe",
        "country": ["Andorra", "Austria", "Belgium", "Cyprus", "Finland", "France", "French Guiana", "French Southern Territories", "Germany", "	Greece", "Guadeloupe", "Holy See", "Ireland", "	Italy", "Latvia", "Luxembourg", "Martinique", "Mayotte (DROM France)", "Monaco", "Montenegro", "Netherlands", "Portugal", "	Reunion Island", "San Marino", "Slovakia (Slovak Republic)", "Slovenia", "Spain", "Vatican City State (Holy See)"]
    },
    {
        "currency": "Pounds sterling",
        "code": "GBP",
        "symbol": "£",
        "region": "Europe",
        "country": ["United Kingdom"]
    },
    {
        "currency": "Croatian Kuna",
        "code": "HRK",
        "symbol": "kn",
        "region": "Europe",
        "country": ["Croatia/Hrvatska"]
    },
    {
        "currency": "Georgian lari",
        "code": "GEL",
        "symbol": "₾",
        "region": "Europe",
        "country": ["Georgia"]
    },
    {
        "currency": "Hungarian forint",
        "code": "HUF",
        "symbol": "ft",
        "region": "Europe",
        "country": ["Hungary"]
    },
    {
        "currency": "Norwegian krone",
        "code": "NOK",
        "symbol": "kr",
        "region": "Europe",
        "country": ["Norway"]
    },
    {
        "currency": "Polish zloty",
        "code": "PLN",
        "symbol": "zł",
        "region": "Europe",
        "country": ["Poland"]
    },
    {
        "currency": "Russian ruble",
        "code": "RUB",
        "symbol": "₽",
        "region": "Europe",
        "country": ["Russian Federation"]
    },
    {
        "currency": "Romanian leu",
        "code": "RON",
        "symbol": "lei",
        "region": "Europe",
        "country": ["Romania"]
    },
    {
        "currency": "Swedish krona",
        "code": "SEK",
        "symbol": "kr",
        "region": "Europe",
        "country": ["Sweden"]
    },
    {
        "currency": "Turkish lira",
        "code": "TRY",
        "symbol": "₺",
        "region": "Europe",
        "country": ["Turkey", "Cyprus"]
    },
    {
        "currency": "Ukrainian hryvna",
        "code": "UAH",
        "symbol": "₴",
        "region": "Europe",
        "country": ["Ukraine"]
    },
    {
        "currency": "Emirati dirham",
        "code": "AED",
        "symbol": "د.إ",
        "region": "Middle East and Africa",
        "country": ["United Arab Emirates"]
    },
    {
        "currency": "Israeli shekel",
        "code": "ILS",
        "symbol": "₪",
        "region": "Middle East and Africa",
        "country": ["Israel", "State of Palestine"]
    },
    {
        "currency": "Kenyan shilling",
        "code": "KES",
        "symbol": "Ksh",
        "region": "Middle East and Africa",
        "country": ["Kenya"]
    },
    {
        "currency": "Moroccan dirham",
        "code": "MAD",
        "symbol": ".د.م",
        "region": "Middle East and Africa",
        "country": ["Morocco", "Western Sahara"]
    },
    {
        "currency": "Nigerian naira",
        "code": "NGN",
        "symbol": "₦",
        "region": "Middle East and Africa",
        "country": ["Nigeria"]
    },
    {
        "currency": "South african",
        "code": "ZAR",
        "symbol": "R",
        "region": "Middle East and Africa",
        "country": ["South Africa", "Namibia"]
    },
    {
        "currency": "Brazilian real",
        "code": "BRL",
        "symbol": "R$",
        "region": "Americas",
        "country": ["Brazil"]
    },
    {
        "currency": "Canadian dollars",
        "code": "CAD",
        "symbol": "$",
        "region": "Americas",
        "country": ["Canada"]
    },
    {
        "currency": "Chilean peso",
        "code": "CLP",
        "symbol": "$",
        "region": "Americas",
        "country": ["Chile"]
    },
    {
        "currency": "Colombian peso",
        "code": "COP",
        "symbol": "$",
        "region": "Americas",
        "country": ["Colombia"]
    },
    {
        "currency": "Mexican peso",
        "code": "MXN",
        "symbol": "$",
        "region": "Americas",
        "country": ["Mexico"]
    },
    {
        "currency": "Peruvian sol",
        "code": "PEN",
        "symbol": "S/.",
        "region": "Americas",
        "country": ["Peru"]
    },
    {
        "currency": "US dollar",
        "code": "USD",
        "symbol": "$",
        "region": "Americas",
        "country": ["American Samoa", "Ecuador", "El Salvador", "Guam", "Haiti", "Marshall Islands", "Micronesia, Federal States of", "Northern Mariana Islands", "Palau", "Panama", "Puerto Rico", "Timor Leste", "Turks and Caicos Islands", "Virgin Islands (British)", "Virgin Islands (U.S.)"]
    },
    {
        "currency": "Australian dollars",
        "code": "AUD",
        "symbol": "$",
        "region": "Asia",
        "country": ["Australia", "Christmas Island", "Cocos (Keeling) Islands", "Kiribati", "Nauru"]
    },
    {
        "currency": "Bangladeshi taka",
        "code": "BDT",
        "symbol": "৳",
        "region": "Asia",
        "country": ["Bangladesh"]
    },
    {
        "currency": "Chinese yuan",
        "code": "CNY",
        "symbol": "¥",
        "region": "Asia",
        "country": ["China"]
    },
    {
        "currency": "Hong Kong dollar",
        "code": "HKD",
        "symbol": "$",
        "region": "Asia",
        "country": ["Hong Kong"]
    },
    {
        "currency": "Indonesian rupiah",
        "code": "IDR",
        "symbol": "Rp",
        "region": "Asia",
        "country": ["Indonesia"]
    },
    {
        "currency": "Indian rupee",
        "code": "INR",
        "symbol": "₹",
        "region": "Asia",
        "country": ["Bhutan", "India"]
    },
    {
        "currency": "Japanese yen",
        "code": "JPY",
        "symbol": "¥",
        "region": "Asia",
        "country": ["Japan"]
    },
    {
        "currency": "Malaysian ringgit",
        "code": "MYR",
        "symbol": "RM",
        "region": "Asia",
        "country": ["Malaysia"]
    },
    {
        "currency": "New Zealand dollar",
        "code": "NZD",
        "symbol": "$",
        "region": "Asia",
        "country": ["Cook Islands", "New Zealand", "Niue", "Pitcairn Island", "	Tokelau"]
    },
    {
        "currency": "Philippine peso",
        "code": "PHP",
        "symbol": "₱",
        "region": "Asia",
        "country": ["Philippines"]
    },
    {
        "currency": "Pakistani rupee",
        "code": "PKR",
        "symbol": "Rs",
        "region": "Asia",
        "country": ["Pakistan"]
    },
    {
        "currency": "Singapore dollar",
        "code": "SGD",
        "symbol": "$",
        "region": "Asia",
        "country": ["Singapore"]
    },
    {
        "currency": "Korean won",
        "code": "KRW",
        "symbol": "₩",
        "region": "Asia",
        "country": "Korea (South Korea)"
    },
    {
        "currency": "Sri Lankan rupee",
        "code": "LKR",
        "symbol": "Rs",
        "region": "Asia",
        "country": ["Sri Lanka"]
    },
    {
        "currency": "Thai baht",
        "code": "THB",
        "symbol": "฿",
        "region": "Asia",
        "country": ["	Thailand"]
    },
    {
        "currency": "Vietnamese dong",
        "code": "VND",
        "symbol": "₫",
        "region": "Asia",
        "country": ["Vietnam"]
    }
]