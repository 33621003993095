import React from "react";
import './styles.css'
import TextField from '@mui/material/TextField';

const CommonInputFloatFull = ({ type, classname = "", placeholder, value, onChange, id, onBlur, isRequired = false, isDisable = false, isSubtext = false, subText, multiline = false, rows = 1, onFocus }) => {
    return (
        <div className={`common-input-margin common-input-full-container ${classname}`}>
            <TextField
                helperText={isSubtext ? subText : ''}
                className="common-input-full"
                type={type}
                required={isRequired}
                id={id}
                label={placeholder}
                value={value}
                onChange={onChange}
                disabled={isDisable}
                onBlur={onBlur ? onBlur : null}
                onFocus={onFocus ? onFocus : null}
                fullWidth
                color='primary'
                size="medium"
                multiline={multiline}
                rows={rows}
            />
        </div>
    )
}

export default CommonInputFloatFull