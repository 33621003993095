export const InvoiceActionTypes = {
    LOGOUT: 'LOGOUT',

    ADD_INVOICE_REQUEST: 'ADD_INVOICE_REQUEST',
    ADD_INVOICE_REQUEST_SUCCESS: 'ADD_INVOICE_REQUEST_SUCCESS',
    ADD_INVOICE_REQUEST_ERROR: 'ADD_INVOICE_REQUEST_ERROR',
    ADD_INVOICE_REQUEST_STATUS: 'ADD_INVOICE_REQUEST_STATUS',

    INVOICE_LIST_REQUEST: 'INVOICE_LIST_REQUEST',
    INVOICE_LIST_REQUEST_SUCCESS: 'INVOICE_LIST_REQUEST_SUCCESS',
    INVOICE_LIST_REQUEST_ERROR: 'INVOICE_LIST_REQUEST_ERROR',

    INVOICE_DETAIL_REQUEST: 'INVOICE_DETAIL_REQUEST',
    INVOICE_DETAIL_REQUEST_SUCCESS: 'INVOICE_DETAIL_REQUEST_SUCCESS',
    INVOICE_DETAIL_REQUEST_ERROR: 'INVOICE_DETAIL_REQUEST_ERROR',

    INVOICE_UPDATE_PAYMENT_REQUEST: 'INVOICE_UPDATE_PAYMENT_REQUEST',
    INVOICE_UPDATE_PAYMENT_REQUEST_SUCCESS: 'INVOICE_UPDATE_PAYMENT_REQUEST_SUCCESS',
    INVOICE_UPDATE_PAYMENT_REQUEST_ERROR: 'INVOICE_UPDATE_PAYMENT_REQUEST_ERROR',
    INVOICE_UPDATE_PAYMENT_REQUEST_STATUS: 'INVOICE_UPDATE_PAYMENT_REQUEST_STATUS',

    COMMON_ERROR: 'COMMON_ERROR',
}
