import { useEffect, useState } from 'react'
import './styles.css'
import Select from 'react-select'
import { isNull } from '../../utils/Utils'
import { PrivacyInformationType } from '../../utils/PrivacyInformationCollection'
import { ORGANISATION_DETAIL } from '../../utils/AppConstants'
import ScreenLabel from '../../components/label/screen-label/screenLabel'

const PrivacyPolicyPage = () => {
    const [displayGeneralPrivacy, setDisplayGeneralPrivacy] = useState(true)
    const [displayCaliforniaPrivacy, setDisplayCaliforniaPrivacy] = useState(true)
    const [privacyDropdown, setPrivacyDropdown] = useState();
    const privacyList = [
        {
            type: 'GLOBAL_POLICY',
            value: 'Global Policy'
        },
        {
            type: 'CALIFORNIA_POLICY',
            value: 'California Policy'
        }
    ]

    useEffect(() => {
        setPrivacyDropdown(privacyList[0])
    }, [])

    useEffect(() => {
        if (!isNull(privacyDropdown)) {
            if (privacyDropdown.type === 'GLOBAL_POLICY') {
                setDisplayGeneralPrivacy(true)
                setDisplayCaliforniaPrivacy(false)
            } else if (privacyDropdown.type === 'CALIFORNIA_POLICY') {
                setDisplayGeneralPrivacy(false)
                setDisplayCaliforniaPrivacy(true)
            }
        }
    }, [privacyDropdown])
    return (
        <div className='pp-container'>
            <ScreenLabel label='Privacy Policy' />
            <div className='pp-label'>Privacy Statement</div>
            <div className='pp-updateLabel'>Last updated on [01-01-2023].</div>
            <div className="pp-select-row">
                {<Select
                    className='pp-select'
                    value={privacyDropdown}
                    getOptionLabel={e => e.value}
                    getOptionValue={e => e}
                    options={privacyList}
                    placeholder='Select Privacy Policy Option'
                    onChange={e => {
                        setPrivacyDropdown(e);
                    }} />}
            </div>
            {displayGeneralPrivacy && <div>
                <div className='pp-text'>We care about your privacy and are committed to protecting your personal data. This privacy statement will inform you how we handle your personal data, your privacy rights, and how the law protects you. Please read this privacy statement carefully before using our Services.</div>
                <div className='pp-text'>This privacy statement applies to your use of any products, services, content, features, technologies, or functions, and all related websites, mobile apps, mobile sites, or other online platforms or applications offered to you by us (collectively the -<span className='text-span-bold'>"Services/Platform"</span>).</div>
                <div className='pp-text-label'>1. What data do we collect about you?</div>
                <div className='pp-first-inline'>
                    <div className='pp-text-label'>1.1.1 Data provided through direct interactions</div>
                    <div className='pp-first-inline'>
                        <div className='pp-text-bold'>Registration and other account information</div>
                        <div className='pp-text'>When you register to use our Services, we collect the following information about you for account registration and identification on our system:</div>
                        <div className='pp-vertical-div'>
                            <div className='pp-subitem'>Your name includes both first name and last name;</div>
                            <div className='pp-subitem'>E-mail address;</div>
                            <div className='pp-subitem'>Mobile number;</div>
                        </div>
                        <div className='pp-text-bold'>Validation of your GoMint account</div>
                        <div className='pp-text'>We validate the accounts of GoMint users by using the email addresses and mobile number verification to ensure that each account is associated with a real and unique user. This validation process is initiated during account registration. This process is entirely free of charge.</div>
                    </div>
                    <div className='pp-text-label'>1.1.2 Data we collect automatically when you use our Services</div>
                    <div className='pp-first-inline'>
                        <div className='pp-text'>When you interact with our Services, we automatically collect the following information about you:</div>
                        <div className='pp-text-bold'>Location information</div>
                        <div className='pp-subitem'> If you register on our platform, we collect user Addresses, Country, State, City, and Zipcode/Pincode. Your location data allows you to search for mechanics within your location.  </div>
                    </div>
                </div>
                <div className='pp-text-label'>2. Why do we process your personal information?</div>
                <div className='pp-first-inline'>
                    <div className='pp-text'>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</div>
                    <div>
                        Where we need to perform the contract, we are about to enter into or have entered into with you.
                        Where it is necessary for our legitimate interests to improve our Services and to provide you a safe and secure Platform.
                        Where we need to comply with a legal or regulatory obligation.

                    </div>
                    <div className='pp-text'>In certain circumstances, we may also process your personal data based on your consent. If we do this, we will let you know the purpose and the category of personal data to be processed at the time we seek your consent.</div>
                    <div className='pp-text'>We have set out below a description of the reasons for which we use your personal data, [and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are, where appropriate].</div>
                    <div className='pp-text-label'>2.1 For providing access and delivering Services through our Platform</div>
                    <div className='pp-first-inline'>
                        <div className='pp-text'>If you log in using your email id, we use your first name and last name, mobile number, and/or e-mail address to identify you as a user and provide access to our Platform.</div>
                        <div className='pp-text'>We process the above information for the adequate performance of our contract with you.</div>
                    </div>
                    <div className='pp-text-label'>2.2 For improving your experience on the Platform and developing new functionalities of the Platform</div>
                    <div className='pp-first-inline'>
                        <div className='pp-text'><span className='pp-text-span-bold'>1.</span> If you choose to provide us with your location data, we use your location data for the following purposes:</div>
                        <div>To enhance the performance of platform Services and to personalize the content we direct towards you. For example - with the help of location data we display mechanic listings that are in your vicinity to improve your user experience. In any case, we will not process your location data for any other purposes but those explained in this Privacy Statement.</div>
                        <div className='pp-text'><span className='pp-text-span-bold'>2.</span> With the help of your account information, which includes your email ID and phone number, we map the different devices (such as desktop, mobile, tablets) used by you to access our Platform. This allows us to associate your activity on our Platform across devices and helps us in providing you a seamless experience no matter which device you use.</div>
                        <div className='pp-text'><span className='pp-text-span-bold'>3.</span> We use the images you upload and the descriptions you provide in your event or alert information to improve the identification and presentation of listings. This helps us to improve our Services and to provide you with a better user experience.</div>
                        <div className='pp-text'>We process the above information for the adequate performance of our contract with you and on the basis of our legitimate interest to improve your experience of our Services.</div>
                    </div>
                    <div className='pp-text-label'>2.3 To take your feedback, promote and offer you services that may be of your interest</div>
                    <div className='pp-first-inline'>
                        <div className='pp-text'><span className='pp-text-span-bold'>1.</span> We use your mobile number, log data, and unique device identifiers to administer and protect our Platform (including troubleshooting, data analysis, testing, fraud prevention, system maintenance, support, reporting, and hosting of data).</div>
                        <div className='pp-text'><span className='pp-text-span-bold'>2.</span> To prevent and detect fraud on our Services, we make use of algorithms that recognize suspicious or fraudulent user behaviour based on certain information such as user activity and posted content, which may lead to us banning certain user accounts. Automated banning happens when there is a very high degree of certainty that an account or action is fraudulent. Otherwise, human review takes place by selecting customer safety and security specialists on the basis of highly restrictive access rights. We use this form of automated decision-making on the basis of our legitimate interest to detect and prevent fraud and to keep our Services safe and secure for our users. If you think that your account was wrongfully banned, you can contact our Customer Support team, in which case our team will review the decision to ban your account.</div>
                        <div className='pp-text'><span className='pp-text-span-bold'>3.</span> We may contact you through your registered mobile number or email id in order to take feedback from you about our Services.</div>
                        <div className='pp-text'><span className='pp-text-span-bold'>4.</span> We use your email address and mobile number (by SMS) to make suggestions and recommendations to you about our Services that may be of interest to you.</div>
                        <div className='pp-text'>We process the above information based on our legitimate interest in undertaking marketing activities to offer you Services that may be of your interest. Specifically, you may receive certain marketing communications from us:</div>
                        <div className='pp-first-inline'>
                            <div className='pp-vertical-div'>
                                <div className='pp-text'>By any preferred means of communication if you have requested such information from us.</div>
                                <div className='pp-text'>By email or phone, regarding similar products and services, if you already use our Services or acquired some of our products.</div>
                                <div className='pp-text'>By phone or email, if you provided us with your details when you entered a competition; or</div>
                                <div className='pp-text'>By phone or email, if you have provided your feedback for our Services through our Platform, social media, or any other means.</div>
                            </div>
                        </div>
                        <div className='pp-text'>Being a registered user on our Platform, please note that if you have registered yourself on DND/DNC/NCPR services, you will still receive the above communications.</div>
                        <div className='pp-text'>You can ask us to stop sending you such marketing communication at any time by contacting our customer support team.</div>
                    </div>
                </div>
                <div className='pp-text-label'>3. How will we inform you about changes in our privacy statement?</div>
                <div className='pp-first-inline'>
                    <div className='pp-text'>We may amend and update this privacy statement from time to time. We will notify you of material changes to this privacy statement as appropriate under the circumstances and as required by applicable laws, e.g. by placing a prominent notice within our Services or by sending you a message via our Services or by sending you an email. If you do not agree with the way we are processing your personal data and the choices we are providing to you, you may close your account at any time by connecting with the customer support team.</div>
                </div>
                <div className='pp-text-label'>4. Communication</div>
                <div className='pp-first-inline'>
                    <div className='pp-text'>We will communicate with you by email, SMS, or in the app notification in connection with our Services/Platform to confirm your registration, to inform you in a case related to our Services. As it is imperative for us to provide you with such transactional messages you may not be able to opt-out of such messages.</div>
                </div>
                <div className='pp-text-label'>5. Who do we share your data with?</div>
                <div className='pp-first-inline'>
                    <div className='pp-text'>We may have to share your personal data with the parties set out below for the purposes set out above.</div>
                    <div className='pp-text'><span className='pp-text-span-bold'>Corporate affiliates:</span> We may share your data with our group companies, which are located within as well as outside the country, and help us in providing business operation services such as product enhancements, customer support, and fraud detection mechanism.</div>
                    <div className='pp-text'><span className='pp-text-span-bold'>Third-Party Service Providers:</span>  We use third-party service providers to help us deliver certain aspects of our Services, for example, cloud storage facilities.
                        We conduct checks on our third-party service providers and require them to respect the security of your personal data and to treat it in accordance with the law. We do not allow them to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.
                    </div>
                    <div className='pp-text'><span className='pp-text-span-bold'>Advertising and analytics providers:</span> In order to improve our Services, we will sometimes share your non-identifiable information with analytics providers that help us analyze how people are using our Platform/Service. We share your information with them in a non-identifiable form for monitoring and reporting the effectiveness of the campaign delivery to our business partners and for internal business analysis.</div>
                    <div className='pp-text'><span className='pp-text-span-bold'>Law enforcement authorities, regulators, and others:</span> We may disclose your personal data to law enforcement authorities, regulators, governmental or public bodies, and other relevant third parties that comply with any legal or regulatory requirements.</div>
                    <div className='pp-text'>We may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy statement.</div>
                </div>
                <div className='pp-text-label'>6. Where do we store your data and for how long?</div>
                <div className='pp-first-inline'>
                    <div className='pp-text'>The data we collect about you will be stored and processed in globally reputed third-party service provider servers in order to provide the best possible user experience. For example – for fast website or mobile application build up.</div>
                    <div className='pp-text'>We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</div>
                    <div className='pp-text'>To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data, and whether we can achieve those purposes through other means, and the applicable legal requirements.</div>
                </div>
                <div className='pp-text-label'>7. Technical and organizational measures and processing security</div>
                <div className='pp-first-inline'>
                    <div className='pp-text'>All the information we receive about you is stored on third-party service provider servers and we have implemented technical and organizational measures that are suitable and necessary to protect your personal data. GoMint continually evaluates the security of its network and adequacy of its internal information security program, which is designed to (a) help secure your data against accidental or unlawful loss, access or disclosure, (b) identify reasonably foreseeable risks to the security of the GoMint network, and (c) minimize security risks, including through risk assessment and regular testing.</div>
                    <div className='pp-text'>Please note, despite the measures we have implemented to protect your data, the transfer of data through the Internet or other open networks is never completely secure and there is a risk that your personal data may be accessed by unauthorized third parties.</div>
                </div>
                <div className='pp-text-label'>8. Contact Information</div>
                <div className='pp-first-inline'>
                    <div className='pp-text'>If you have any questions or comments about this notice, our Privacy Statement, the ways in which we collect and use your personal information, your choices and rights regarding such use, please do not hesitate to contact us at:</div>
                    <div className='pp-first-inline'>
                        <div className='pp-text'><span className='pp-text-span-bold'>{ORGANISATION_DETAIL.ORGANISATION_NAME}</span></div>
                        {/* <div className='pp-text'>{ORGANISATION_DETAIL.ORG_ADDRESS_LINE1}</div> */}
                        <div className='pp-text'>{ORGANISATION_DETAIL.ORG_ADDRESS_LINE2}</div>
                        <div className='pp-text'><span className='pp-text-span-bold'>Email:</span> {ORGANISATION_DETAIL.ORG_SUPPORT_EMAIL}</div>
                    </div>

                </div>
            </div>}
            {displayCaliforniaPrivacy && <div>
                <div className='pp-text'>This PRIVACY NOTICE FOR CALIFORNIA RESIDENTS (“Privacy Statement”) supplements the information contained in the Privacy Policy, which is incorporated by reference as if set forth herein.  This Privacy Statement applies solely to visitors, users, and others who reside in the State of California (“consumers” or “you”). We adopt this notice to comply with the California Consumer Privacy Act of 2018 (“CCPA”) and other California privacy laws.  Any terms defined in the CCPA have the same meaning when used in this notice.</div>
                <div className='pp-text-label'>Information We Collect</div>
                <div className='pp-text'>We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device (“personal information”). The categories of information set forth in the chart below are those defined in the CCPA.  This does not mean that all examples of that category of personal information were in fact collected, but reflects a good faith belief that to the best of our knowledge that some of that information may have been collected.  In particular, we have collected the following categories of personal information from consumers within the last 12 months:</div>
                <table class="table table-striped table-hover mt-4">
                    <thead>
                        <tr>
                            <th scope="col" className="loc-table-col-title">Category</th>
                            <th scope="col" className="loc-table-col-title">Example</th>
                            <th scope="col" className="loc-table-col-title">Collected</th>
                        </tr>
                    </thead>
                    <tbody>
                        {PrivacyInformationType.map(element => (
                            <tr className="loc-table-row-container">
                                <td className="loc-table-col-item">{element.category}</td>
                                <td className="loc-table-col-item">{element.example}</td>
                                <td className="loc-table-col-item">{element.collected}</td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                <div className='pp-text-bold'>Personal information does not include:</div>
                <div className='pp-first-inline'>
                    <div className='pp-text'><span className='pp-text-span-bold'>1.</span> Publicly available information from government records.</div>
                    <div className='pp-text'><span className='pp-text-span-bold'>2.</span> De-identified or aggregated consumer information.</div>
                    <div className='pp-text'><span className='pp-text-span-bold'>3.</span> Information excluded from the CCPA's scope, like:</div>
                    <div className='pp-first-inline'>
                        <div className='pp-vertical-div'>
                            <div className='pp-text'><span className='pp-text-span-bold'>A.</span> Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data;</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>B.</span> Personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.</div>
                        </div>
                    </div>
                    <div className='pp-text'>We obtain the categories of personal information listed above from the following categories of sources:</div>
                    <div className='pp-text'><span className='pp-text-span-bold'>1.</span> Directly from our customers.</div>
                    <div className='pp-text'><span className='pp-text-span-bold'>2.</span> Directly and indirectly from activity on the Site.  For example, from submissions through our website portal or website usage details collected automatically.</div>
                    <div className='pp-text'><span className='pp-text-span-bold'>3.</span> From third-parties that interact with us in connection with our products and services.</div>
                </div>
                <div className='pp-text-bold'>Use of Personal Information</div>
                <div className='pp-first-inline'>
                    <div className='pp-text'>We may use or disclose the personal information we collect for one or more of the following business purposes:</div>
                    <div className='pp-first-inline'>
                        <div className='pp-vertical-div'>
                            <div className='pp-text'><span className='pp-text-span-bold'>1.</span> To fulfill or meet the reason for which the information is provided. For example, fulfilling one of your order/transactions.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>2.</span> To provide you with information, products or services that you request from us.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>3.</span> To provide you with email alerts, event registrations and other notices concerning our products or services, or events or news, that may be of interest to you.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>4.</span> To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collections.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>5.</span> To improve the Site and present its contents to you.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>6.</span> For testing, research, analysis and product development.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>7.</span> As necessary or appropriate to protect the rights, property or safety of us, our clients or others.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>8.</span> To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>9.</span> As described to you when collecting your personal information or as otherwise set forth in the CCPA.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>10.</span> To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us is among the assets transferred.</div>
                        </div>
                    </div>
                    <div className='pp-text'>We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.</div>
                </div>
                <div className='pp-text-bold'>Sharing Personal Information</div>
                <div className='pp-first-inline'>
                    <div className='pp-text'>We may disclose your personal information to a third party for a business purpose.  When we disclose personal information for a business purpose, we enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract.</div>
                    <div className='pp-text'>In the preceding 12 months, we have not disclosed personal information to a third party for a business purpose:</div>
                    <div className='pp-text'>We may disclose your personal information for a business purpose to the following categories of third parties:</div>
                    <div className='pp-first-inline'>
                        <div className='pp-vertical-div'>
                            <div className='pp-text'><span className='pp-text-span-bold'>1.</span> Our affiliates.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>2.</span> Service providers.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>3.</span> Third parties to whom you or your agents authorize us to disclose your personal information in connection with products or services we provide to you.</div>
                        </div>
                    </div>
                    <div className='pp-text'>In the preceding 12 months, we have not sold any personal information.</div>
                </div>
                <div className='pp-text-bold'>Your Rights and Choices</div>
                <div className='pp-first-inline'>
                    <div className='pp-text'>The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.</div>
                    <div className='pp-text-label pp-text-label-italic'>Access to Specific Information and Data Portability Rights</div>
                    <div className='pp-text'>You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months. Once we receive and confirm your verifiable consumer request, we will disclose to you:</div>
                    <div className='pp-first-inline'>
                        <div className='pp-vertical-div'>
                            <div className='pp-text'><span className='pp-text-span-bold'>1.</span> The categories of personal information we collected about you.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>2.</span> The categories of sources for the personal information we collected about you.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>3.</span> Our business or commercial purpose for collecting or selling that personal information.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>4.</span> The categories of third parties with whom we share that personal information.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>5.</span> The specific pieces of personal information we collected about you (also called a data portability request).</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>6.</span> If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:</div>
                            <div className='pp-first-inline'>
                                <div className='pp-vertical-div'>
                                    <div className='pp-text'><span className='pp-text-span-bold'>A.</span> sales, identifying the personal information categories that each category of recipient purchased; and</div>
                                    <div className='pp-text'><span className='pp-text-span-bold'>B.</span> disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='pp-text-label pp-text-label-italic'>Deletion Request Rights</div>
                    <div className='pp-text'>You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.</div>
                    <div className='pp-text'>We may deny your deletion request if retaining the information is necessary for us or our service providers to:</div>
                    <div className='pp-first-inline'>
                        <div className='pp-vertical-div'>
                            <div className='pp-text'><span className='pp-text-span-bold'>1.</span> Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>2.</span> Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>3.</span> Debug products to identify and repair errors that impair existing intended functionality.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>4.</span> Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>5.</span> Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 seq.).</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>6.</span> Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>7.</span> Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>8.</span> Comply with a legal obligation.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>9.</span> Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</div>

                        </div>
                    </div>
                    <div className='pp-text-label pp-text-label-italic'>Exercising Access, Data Portability, and Deletion Rights</div>
                    <div className='pp-text'>To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us by:</div>
                    <div className='pp-first-inline'>
                        <div className='pp-text'><span className='pp-text-span-bold'>Emailing us at:</span> support@gomobites.com</div>
                    </div>
                    <div className='pp-text'>Only you or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child.</div>
                    <div className='pp-text'>You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must:</div>
                    <div className='pp-first-inline'>
                        <div className='pp-vertical-div'>
                            <div className='pp-text'><span className='pp-text-span-bold'>1.</span> Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>2.</span> Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</div>
                        </div>
                    </div>
                    <div className='pp-text'>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you.  Making a verifiable consumer request does not require you to create an account with us.  We will only use personal information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request.</div>
                    <div className='pp-text-label pp-text-label-italic'>Response Timing and Format</div>
                    <div className='pp-text'>We endeavor to respond to a verifiable consumer request within 45 days of its receipt.  If we require more time (up to 90 days), we will inform you of the reason and extension period in writing.  If you have an account with us, we will deliver our written response to that account.  If you do not have an account with us, we will deliver our written response by mail or electronically, at your option.  Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request's receipt.  The response we provide will also explain the reasons we cannot comply with a request, if applicable.  For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.</div>
                    <div className='pp-text'>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded.  If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</div>
                </div>
                <div className='pp-text-bold'>Non-Discrimination</div>
                <div className='pp-first-inline'>
                    <div className='pp-text'>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:</div>
                    <div className='pp-first-inline'>
                        <div className='pp-vertical-div'>
                            <div className='pp-text'><span className='pp-text-span-bold'>1.</span> Deny you goods or services.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>2.</span> Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>3.</span> Provide you a different level or quality of goods or services.</div>
                            <div className='pp-text'><span className='pp-text-span-bold'>4.</span> Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</div>
                        </div>
                    </div>
                </div>
                <div className='pp-text-bold'>Changes to Our Privacy Notice</div>
                <div className='pp-first-inline'>
                    <div className='pp-text'>We reserve the right to amend this privacy notice at our discretion and at any time. When we make changes to this privacy notice, we will notify you by email or through a notice on our website homepage.</div>
                </div>
                <div className='pp-text-bold'>Contact Information</div>
                <div className='pp-first-inline'>
                    <div className='pp-text'>If you have any questions or comments about this notice, our Privacy Statement, the ways in which we collect and use your personal information, your choices and rights regarding such use, or wish to exercise your rights under California law, please do not hesitate to contact us at:</div>
                    <div className='pp-first-inline'>
                        <div className='pp-text'><span className='pp-text-span-bold'>{ORGANISATION_DETAIL.ORGANISATION_NAME}</span></div>
                        {/* <div className='pp-text'>{ORGANISATION_DETAIL.ORG_ADDRESS_LINE1}</div> */}
                        <div className='pp-text'>{ORGANISATION_DETAIL.ORG_ADDRESS_LINE2}</div>
                        <div className='pp-text'><span className='pp-text-span-bold'>Email:</span> {ORGANISATION_DETAIL.ORG_SUPPORT_EMAIL}</div>
                    </div>

                </div>
            </div>}
        </div>
    )
}

export default PrivacyPolicyPage