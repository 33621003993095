import React from "react";
import './styles.css'

const CommonButton = ({ onClick, label, isInverted = false, isBig = false }) => {
    return (
        <button
            type='button'
            onClick={onClick}
            className={isInverted === false ?
                isBig === false ? 'btn normal-btn' : 'btn normal-btn normal-btn-bg' :
                isBig === false ? 'btn inverted-btn' : 'btn inverted-btn inverted-btn-bg'}>
            {label}
        </button>)
}

export default CommonButton