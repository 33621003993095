import { BusinessLocationActionTypes } from './businessLocation.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addBusinessLocationSuccess: null,
    addBusinessLocationError: null,
    addBusinessLocationReqStatus: '',
    businessLocationListSuccess: null,
    businessLocationListError: null,
    businessLocationDetailSuccess: null,
    businessLocationDetailError: null,
    updateBusinessLocationDetailSuccess: null,
    updateBusinessLocationDetailError: null,
    updateBusinessLocationDetailReqStatus: ''
}

const businessLocationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BusinessLocationActionTypes.ADD_BUSINESS_LOCATION_REQUEST_SUCCESS:
            return {
                ...state,
                addBusinessLocationSuccess: action.payload,
                addBusinessLocationError: null,
                addBusinessLocationReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case BusinessLocationActionTypes.ADD_BUSINESS_LOCATION_REQUEST_ERROR:
            return {
                ...state,
                addBusinessLocationSuccess: null,
                addBusinessLocationError: action.payload,
                addBusinessLocationReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case BusinessLocationActionTypes.ADD_BUSINESS_LOCATION_REQUEST_STATUS:
            return {
                ...state,
                addBusinessLocationReqStatus: ''
            }
        case BusinessLocationActionTypes.BUSINESS_LOCATION_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                businessLocationListSuccess: action.payload,
                businessLocationListError: null
            }
        case BusinessLocationActionTypes.BUSINESS_LOCATION_LIST_REQUEST_ERROR:
            return {
                ...state,
                businessLocationListSuccess: null,
                businessLocationListError: action.payload
            }
        case BusinessLocationActionTypes.BUSINESS_LOCATION_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                businessLocationDetailSuccess: action.payload,
                businessLocationDetailError: null
            }
        case BusinessLocationActionTypes.BUSINESS_LOCATION_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                businessLocationDetailSuccess: null,
                businessLocationDetailError: action.payload
            }
        case BusinessLocationActionTypes.UPDATE_BUSINESS_LOCATION_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                updateBusinessLocationDetailSuccess: action.payload,
                updateBusinessLocationDetailError: null,
                updateBusinessLocationDetailReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case BusinessLocationActionTypes.UPDATE_BUSINESS_LOCATION_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                updateBusinessLocationDetailSuccess: null,
                updateBusinessLocationDetailError: action.payload,
                updateBusinessLocationDetailReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case BusinessLocationActionTypes.UPDATE_BUSINESS_LOCATION_DETAIL_REQUEST_STATUS:
            return {
                ...state,
                updateBusinessLocationDetailReqStatus: ''
            }
        case BusinessLocationActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default businessLocationReducer