import { CashbackActionTypes } from './cashback.types'

//Add Cashback
export const addCashbackRequest = data => ({
    type: CashbackActionTypes.ADD_CASHBACK_REQUEST,
    payload: data
})

export const addCashbackRequestSuccess = data => ({
    type: CashbackActionTypes.ADD_CASHBACK_REQUEST_SUCCESS,
    payload: data
})

export const addCashbackRequestError = data => ({
    type: CashbackActionTypes.ADD_CASHBACK_REQUEST_ERROR,
    payload: data
})

export const addCashbackRequestStatus = data => ({
    type: CashbackActionTypes.ADD_CASHBACK_REQUEST_STATUS,
    payload: data
})

//Cashback List
export const cashbackListRequest = data => ({
    type: CashbackActionTypes.CASHBACK_REQUEST_LIST_REQUEST,
    payload: data
})

export const cashbackListRequestSuccess = data => ({
    type: CashbackActionTypes.CASHBACK_REQUEST_LIST_REQUEST_SUCCESS,
    payload: data
})

export const cashbackListRequestError = data => ({
    type: CashbackActionTypes.CASHBACK_REQUEST_LIST_REQUEST_ERROR,
    payload: data
})

//Cashback Update Status
export const cashbackUpdateStatusRequest = data => ({
    type: CashbackActionTypes.CASHBACK_UPDATE_STATUS_REQUEST,
    payload: data
})

export const cashbackUpdateStatusRequestSuccess = data => ({
    type: CashbackActionTypes.CASHBACK_UPDATE_STATUS_REQUEST_SUCCESS,
    payload: data
})

export const cashbackUpdateStatusRequestError = data => ({
    type: CashbackActionTypes.CASHBACK_UPDATE_STATUS_REQUEST_ERROR,
    payload: data
})

export const cashbackUpdateStatusRequestStatus = data => ({
    type: CashbackActionTypes.CASHBACK_UPDATE_STATUS_REQUEST_STATUS,
    payload: data
})