import { BusinessActionTypes } from './business.types'

//Add Business
export const addBusinessRequest = data => ({
    type: BusinessActionTypes.ADD_BUSINESS_REQUEST,
    payload: data
})

export const addBusinessRequestSuccess = data => ({
    type: BusinessActionTypes.ADD_BUSINESS_REQUEST_SUCCESS,
    payload: data
})

export const addBusinessRequestError = data => ({
    type: BusinessActionTypes.ADD_BUSINESS_REQUEST_ERROR,
    payload: data
})

export const addBusinessRequestStatus = data => ({
    type: BusinessActionTypes.ADD_BUSINESS_REQUEST_STATUS,
    payload: data
})

//User Business List
export const userBusinessListRequest = data => ({
    type: BusinessActionTypes.USER_BUSINESS_LIST_REQUEST,
    payload: data
})

export const userBusinessListRequestSuccess = data => ({
    type: BusinessActionTypes.USER_BUSINESS_LIST_REQUEST_SUCCESS,
    payload: data
})

export const userBusinessListRequestError = data => ({
    type: BusinessActionTypes.USER_BUSINESS_LIST_REQUEST_ERROR,
    payload: data
})

//Business Details
export const businessDetailsRequest = data => ({
    type: BusinessActionTypes.BUSINESS_DETAIL_REQUEST,
    payload: data
})

export const businessDetailsRequestSuccess = data => ({
    type: BusinessActionTypes.BUSINESS_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const businessDetailsRequestError = data => ({
    type: BusinessActionTypes.BUSINESS_DETAIL_REQUEST_ERROR,
    payload: data
})

//Update Business
export const updateBusinessDetailsRequest = data => ({
    type: BusinessActionTypes.UPDATE_BUSINESS_REQUEST,
    payload: data
})

export const updateBusinessDetailsRequestSuccess = data => ({
    type: BusinessActionTypes.UPDATE_BUSINESS_REQUEST_SUCCESS,
    payload: data
})

export const updateBusinessDetailsRequestError = data => ({
    type: BusinessActionTypes.UPDATE_BUSINESS_REQUEST_ERROR,
    payload: data
})

export const updateBusinessDetailsRequestStatus = data => ({
    type: BusinessActionTypes.UPDATE_BUSINESS_REQUEST_STATUS,
    payload: data
})