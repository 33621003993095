export const CustomerCashbackActionTypes = {
    LOGOUT: 'LOGOUT',
    COMMON_ERROR: 'COMMON_ERROR',

    ADD_CUSTOMER_CASHBACK_REQUEST: 'ADD_CUSTOMER_CASHBACK_REQUEST',
    ADD_CUSTOMER_CASHBACK_REQUEST_SUCCESS: 'ADD_CUSTOMER_CASHBACK_REQUEST_SUCCESS',
    ADD_CUSTOMER_CASHBACK_REQUEST_ERROR: 'ADD_CUSTOMER_CASHBACK_REQUEST_ERROR',
    ADD_CUSTOMER_CASHBACK_REQUEST_STATUS: 'ADD_CUSTOMER_CASHBACK_REQUEST_STATUS',

    CUSTOMER_CASHBACK_LIST_REQUEST: 'CUSTOMER_CASHBACK_LIST_REQUEST',
    CUSTOMER_CASHBACK_LIST_REQUEST_SUCCESS: 'CUSTOMER_CASHBACK_LIST_REQUEST_SUCCESS',
    CUSTOMER_CASHBACK_LIST_REQUEST_ERROR: 'CUSTOMER_CASHBACK_LIST_REQUEST_ERROR'
}
