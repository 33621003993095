export const getHeadingContent = (country = 'USA') => {
    let obj = landingPageHeadingContent.find(e => e.country === country)
    return obj ? obj.text : ''
}

export const getHeading2Content = (country = 'USA') => {
    let obj = landingPageHeading2Content.find(e => e.country === country)
    return obj ? obj.text : ''
}

export const getHeading3Content = (country = 'USA') => {
    let obj = landingPageHeading3Content.find(e => e.country === country)
    return obj ? obj.text : ''
}

export const getViewOffersContent = (country = 'USA') => {
    let obj = landingPageViewOfferContent.find(e => e.country === country)
    return obj ? obj.text : ''
}

let landingPageHeadingContent = [
    {
        country: 'USA',
        language: 'EN',
        text: 'Earn More with Extra Cashback'
    },
    {
        country: 'INDIA',
        language: 'EN',
        text: 'Earn More with Extra Cashback'
    },
    {
        country: 'CANADA',
        language: 'EN',
        text: 'Earn More with Extra Cashback'
    },
    {
        country: 'AUSTRALIA',
        language: 'EN',
        text: 'Earn More with Extra Cashback'
    },
    {
        country: 'PAKISTAN',
        language: 'EN',
        text: 'Earn More with Extra Cashback'
    },
    {
        country: 'OMAN',
        language: 'EN',
        text: 'Earn More with Extra Cashback'
    },
    {
        country: 'UAE',
        language: 'EN',
        text: 'Earn More with Extra Cashback'
    }
]

let landingPageHeading2Content = [
    {
        country: 'USA',
        language: 'EN',
        text: 'Earn more Dollars with offers on GoMint.'
    },
    {
        country: 'INDIA',
        language: 'EN',
        text: 'Earn more Rupees with offers on GoMint.'
    },
    {
        country: 'CANADA',
        language: 'EN',
        text: 'Earn more Dollars with offers on GoMint.'
    },
    {
        country: 'AUSTRALIA',
        language: 'EN',
        text: 'Earn more Dollars with offers on GoMint.'
    },
    {
        country: 'PAKISTAN',
        language: 'EN',
        text: 'Earn more Rupees with offers on GoMint.'
    },
    {
        country: 'OMAN',
        language: 'EN',
        text: 'Earn more Omani Rials with offers on GoMint.'
    },
    {
        country: 'UAE',
        language: 'EN',
        text: 'Earn more Dirhams with offers on GoMint.'
    }
]

let landingPageHeading3Content = [
    {
        country: 'USA',
        language: 'EN',
        text: 'Get more from every commute, meal, shopping and grocery trip.'
    },
    {
        country: 'INDIA',
        language: 'EN',
        text: 'Make the most of every commute, meal, shopping, and grocery trip.'
    },
    {
        country: 'CANADA',
        language: 'EN',
        text: 'Get more out of every commute, meal, shopping trip, and grocery run.'
    },
    {
        country: 'AUSTRALIA',
        language: 'EN',
        text: 'Get more out of every commute, meal, shopping trip, and grocery run.'
    },
    {
        country: 'PAKISTAN',
        language: 'EN',
        text: 'Make the most out of every ride, meal, shopping spree, and grocery run.'
    },
    {
        country: 'OMAN',
        language: 'EN',
        text: 'Enhance every journey, meal, shopping spree, and grocery run.'
    },
    {
        country: 'UAE',
        language: 'EN',
        text: 'Enjoy more rewards with every commute, meal, shopping, and grocery trip.'
    }
]

let landingPageViewOfferContent = [
    {
        country: 'USA',
        language: 'EN',
        text: 'View Offers'
    },
    {
        country: 'INDIA',
        language: 'EN',
        text: 'View Offers'
    },
    {
        country: 'CANADA',
        language: 'EN',
        text: 'View Offers'
    },
    {
        country: 'AUSTRALIA',
        language: 'EN',
        text: 'View Deals'
    },
    {
        country: 'PAKISTAN',
        language: 'EN',
        text: 'View Offers'
    },
    {
        country: 'OMAN',
        language: 'EN',
        text: 'View Offers'
    },
    {
        country: 'UAE',
        language: 'EN',
        text: 'View Offers'
    }
]