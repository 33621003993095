import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addOfferAPI, offerDetailAPI, offerListAPI, offerUpdateStatusAPI
} from '../api-requests/offer';
import {
    addOfferRequestError, addOfferRequestSuccess, offerDetailRequestError,
    offerDetailRequestSuccess, offerListRequestError, offerListRequestSuccess,
    offerUpdateStatusRequestError, offerUpdateStatusRequestSuccess
} from './offer.action';
import { OfferActionTypes } from './offer.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleOfferUpdateStatus({ payload }) {
    try {
        const response = yield call(offerUpdateStatusAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(offerUpdateStatusRequestSuccess(decryptedData))
            } else {
                yield put(offerUpdateStatusRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: OfferActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(offerUpdateStatusRequestError(error.response.data))
        }
    }
}

export function* offerUpdateStatusReq() {
    yield takeLatest(OfferActionTypes.OFFER_UPDATE_STATUS_REQUEST, handleOfferUpdateStatus)
}

function* handleOfferList({ payload }) {
    try {
        const response = yield call(offerListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(offerListRequestSuccess(decryptedData))
            } else {
                yield put(offerListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: OfferActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(offerListRequestError(error.response.data))
        }
    }
}

export function* offerListReq() {
    yield takeLatest(OfferActionTypes.OFFER_LIST_REQUEST, handleOfferList)
}

function* handleOfferDetail({ payload }) {
    try {
        const response = yield call(offerDetailAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(offerDetailRequestSuccess(decryptedData))
            } else {
                yield put(offerDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: OfferActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(offerDetailRequestError(error.response.data))
        }
    }
}

export function* offerDetailReq() {
    yield takeLatest(OfferActionTypes.OFFER_DETAIL_REQUEST, handleOfferDetail)
}

function* handleAddOffer({ payload }) {
    try {
        const response = yield call(addOfferAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addOfferRequestSuccess(decryptedData))
            } else {
                yield put(addOfferRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: OfferActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(addOfferRequestError(error.response.data))
        }
    }
}

export function* addOfferReq() {
    yield takeLatest(OfferActionTypes.ADD_OFFER_REQUEST, handleAddOffer)
}