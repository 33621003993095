import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import Select from 'react-select'
import moment from "moment";
import { offerListRequest } from '../../../redux/offer/offer.action'
import { invoiceListRequest } from '../../../redux/invoice/invoice.action'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { displayCurrencySymbolLeft, isEmpty, isLoggedInUserCustomer, isLoggedInUserOperator, isLoggedInUserVendor, isNull, removeSpecialCharAndChangeToPascal } from "../../../utils/Utils";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import { ROUTES } from "../../../utils/AppConstants";
import TopButton from "../../../components/button/top-button/topButton";
import { userBusinessListRequest } from '../../../redux/business/business.action'
import { businessLocationListRequest } from '../../../redux/business-location/businessLocation.action'

const InvoiceList = props => {
    const [isLoading, setLoading] = useState(false)
    const { user } = useSelector(state => state.user)
    const { invoiceListSuccess, invoiceListError } = useSelector((state) => state.invoice)
    const { userBusinessListSuccess, userBusinessListError } = useSelector(state => state.business)
    const { businessLocationListSuccess, businessLocationListError } = useSelector(state => state.businessLocation)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [selectedBusiness, setSelectedBusiness] = useState()
    const [selectedLocation, setSelectedLocation] = useState()

    useEffect(() => {
        if (isLoggedInUserVendor(user)) {
            fetchBusinessList()
        } else {
            fetchInvoiceList()
        }
    }, [])

    const fetchBusinessList = () => {
        setLoading(true)
        const data = {
            userId: user.data.userDetails.userId
        }
        dispatch(userBusinessListRequest(data))
    }

    const fetchInvoiceList = () => {
        setLoading(true);
        const data = {}
        if (isLoggedInUserOperator(user)) {
            data.operatorId = user.data.userDetails.userId
        }
        if (isLoggedInUserVendor(user)) {
            if (!isEmpty(selectedBusiness) && !isEmpty(selectedLocation)) {
                data.businessId = selectedBusiness.businessId
                data.locationId = selectedLocation.locationId
            }
        }
        dispatch(invoiceListRequest(data))
    }

    useEffect(() => {
        if (!isEmpty(userBusinessListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(userBusinessListError)) {
            setLoading(false)
        }
    }, [userBusinessListSuccess, userBusinessListError])

    useEffect(() => {
        if (!isEmpty(businessLocationListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(businessLocationListError)) {
            setLoading(false)
        }
    }, [businessLocationListSuccess, businessLocationListError])

    useEffect(() => {
        if (!isNull(selectedBusiness)) {
            setLoading(true)
            const data = {
                businessId: selectedBusiness.businessId
            }
            dispatch(businessLocationListRequest(data))
        }
    }, [selectedBusiness])

    useEffect(() => {
        if (!isNull(selectedLocation)) {
            fetchInvoiceList()
        }
    }, [selectedLocation])

    useEffect(() => {
        if (!isNull(invoiceListSuccess)) {
            setLoading(false)
        } else if (!isNull(invoiceListError)) {
            setLoading(false)
        }
    }, [invoiceListSuccess, invoiceListError])

    const handleCardClick = element => {
        navigate(ROUTES.invoiceDetail, { state: element.invoiceId })
    }

    const handleNewInvoiceClick = () => {
        navigate(ROUTES.createInvoice)
    }

    const getAmountWithCurrency = (e, amount) => {
        return (displayCurrencySymbolLeft(e.currencyRegion) ?
            <span>
                {e.currencySymbol} {amount}
            </span>
            : <span>
                {amount} {e.currencySymbol}
            </span>
        )
    }

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow className='margin-top-10'>
                    <ScreenLabel label='Invoices' />
                    {isLoggedInUserOperator(user) && <TopButton label='New Invoice'
                        onClick={() => { handleNewInvoiceClick() }} />}
                </CommonInputRow>

                {(isLoggedInUserVendor(user)) &&
                    <CommonInputRow>
                        <div className="il-select-container">
                            <div className="il-select-heading">Select Business</div>
                            {!isEmpty(userBusinessListSuccess) && !isEmpty(userBusinessListSuccess.data) && <Select
                                className='select-full-width-no-border'
                                value={selectedBusiness}
                                getOptionLabel={e => e.businessName}
                                getOptionValue={e => e}
                                options={userBusinessListSuccess.data}
                                placeholder='Select Business'
                                onChange={e => setSelectedBusiness(e)} />}
                            {!isEmpty(userBusinessListError) && <div className="il-text">{userBusinessListError.message}</div>}
                        </div>
                        <div className="il-select-container">
                            <div className="il-select-heading">Select Location</div>
                            {businessLocationListSuccess && businessLocationListSuccess.data && <Select
                                className='select-full-width-no-border'
                                value={selectedLocation}
                                getOptionLabel={e => e.name}
                                getOptionValue={e => e}
                                options={businessLocationListSuccess.data}
                                placeholder='Select Location'
                                onChange={e => setSelectedLocation(e)} />}
                            {!isEmpty(businessLocationListError) && <div className="il-text">{businessLocationListError.message}</div>}
                        </div>
                    </CommonInputRow>

                }
                {!isNull(invoiceListSuccess) && <div className="table-scroll-container">
                    {!isNull(invoiceListSuccess.data) && <table class="table table-hover margin-top-10">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">Invoice Number</th>
                                <th scope="col" className="rl-table-col-title text-center">Invoice Amount</th>
                                <th scope="col" className="rl-table-col-title text-center">Payment Status</th>
                                <th scope="col" className="rl-table-col-title text-center">Payment Mode</th>
                                {isLoggedInUserOperator(user) && <th scope="col" className="rl-table-col-title text-center">Vendor</th>}
                                {isLoggedInUserVendor(user) && <th scope="col" className="rl-table-col-title text-center">Operator</th>}
                                <th scope="col" className="rl-table-col-title text-center">Invoice Date</th>
                                <th scope="col" className="rl-table-col-title text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoiceListSuccess.data.map(element => (
                                <tr className="rl-table-row-container">
                                    <td className="rl-table-col-item">{element.invoiceId}</td>
                                    <td className="rl-table-col-item text-center">{getAmountWithCurrency(element, element.finalAmount)}</td>
                                    <td className="rl-table-col-item text-center">{removeSpecialCharAndChangeToPascal(element.paymentStatus)}</td>
                                    <td className="rl-table-col-item text-center">{element.paymentMode}</td>
                                    {isLoggedInUserOperator(user) && <td className='rl-table-col-item text-center'>{element.businessDetail.businessName}</td>}
                                    {isLoggedInUserVendor(user) && <td className='rl-table-col-item text-center'>{element.operatorDetail.firstName} {element.operatorDetail.lastName}</td>}
                                    <td className="rl-table-col-item text-center">{moment(element.createdOn).utc().format('DD MMM YYYY')}</td>
                                    <td className="rl-table-col-item text-center" >
                                        <div className="resl-btn-container" onClick={() => { handleCardClick(element) }}>View Details</div></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>}
                {!isNull(invoiceListError) && <div className="error-text-container">{invoiceListError.message}</div>}
            </CommonScreenContent >
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )

}
export default InvoiceList