import axios from 'axios'
import { config } from '../../config'
import { APP_CONSTANTS } from '../../utils/AppConstants'
import API_ENDPOINTS from './endpoints'

export function addInvoiceAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.invoice.createInvoice}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function invoiceListAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.invoice.invoiceList}`,
        params: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function invoiceDetailAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.invoice.invoiceDetail}/${payload}`,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function invoiceUpdateStatusAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.invoice.updatePayment}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}